import { type WelkinEncounterTemplateNames } from '../../welkin';
import { SchedulingError } from './SchedulingErrorBase.error';

export class AppointmentNotAvailableInProgram extends SchedulingError {
   override name = 'scheduling.appointmentNotAvailable';

   constructor(appointmentTemplate: WelkinEncounterTemplateNames) {
      super(
         `Appointment type ${appointmentTemplate} not available in current patient program`,
      );
   }
}
