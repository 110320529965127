import { z } from 'zod';
import { BreathingExercises } from './breathing-exercises.const';

export const addBreathingExerciseDtoSchema = z.object({
   breathingExercise: z.nativeEnum(BreathingExercises),
   sessionDurationInSeconds: z.number(),
});

export type AddBreathingExerciseDto = z.infer<
   typeof addBreathingExerciseDtoSchema
>;
