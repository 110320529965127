import { z } from 'zod';
import { intExtendedField } from '../../common';

export const getPatientsSchema = z.object({
   query: z.string().optional(),
   page: intExtendedField.optional(),
   pageSize: intExtendedField.optional(),
   sort: z.string().optional(),
});

export type GetPatientsParams = z.infer<typeof getPatientsSchema>;
