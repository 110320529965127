import { z } from 'zod';
import { nmiMerchantFieldsSchema } from '../../nmi';

export const createTransactionSchema = z.object({
   sourceTransactionId: z.string().optional(),
   amount: z.string(), // todo: numeric string
   noSubscription: z.boolean(),

   orderId: z.number().int(), // magento order id

   merchantFields: nmiMerchantFieldsSchema.optional(),

   sendNmiReceipt: z.boolean().optional(),
});

export type CreateTransactionParams = z.infer<typeof createTransactionSchema>;
