import { z } from 'zod';
import { slugField } from '../common';

export const createTreatmentMethodSchema = z.object({
   name: z.string().min(1, { message: 'Name is required' }),
   slug: slugField,
   order: z.number().optional(),
});

export type CreateTreatmentMethodDto = z.infer<
   typeof createTreatmentMethodSchema
>;
