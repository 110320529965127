import { WelkinRoleDb } from '@innerwell/prisma-app-generated';
import { z } from 'zod';

export const createEncounterSchema = z.object({
   title: z.string().min(1, { message: 'Title is required' }),
   welkinEncounterName: z
      .string()
      .min(1, { message: 'Welkin encounter name is required' }),
   timetapId: z.preprocess(
      (x) => Number(x),
      z.number().min(1, { message: 'Timetap ID is required' }),
   ),
   hasInPersonOption: z.boolean(),
   role: z.enum([WelkinRoleDb.PrescribingClinician, WelkinRoleDb.Therapist]),
});

export type CreateEncounterDto = z.infer<typeof createEncounterSchema>;
