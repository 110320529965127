import { z } from 'zod';

export const createPrescriptionFromExistingSchema = z.object({
   // id within our database
   prescriptionId: z.string().uuid(),
   overrides: z
      .object({
         // Db record id
         clinicianId: z.string().uuid().optional(),
         // Db record id
         medicationId: z.string().uuid().optional(),
      })
      .optional(),
});

export type CreatePrescriptionFromExistingDto = z.infer<
   typeof createPrescriptionFromExistingSchema
>;
