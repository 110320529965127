import { z } from 'zod';

export const generateMeetingUrlParamsSchema = z.object({
   patientId: z.string().uuid(),
   encounterId: z.string().uuid(),
});

export type GenerateMeetingUrlParamsDto = z.infer<
   typeof generateMeetingUrlParamsSchema
>;
