import { z } from 'zod';
import { dateTimeField } from '../common';
import { updateSitterDtoSchema } from './program.patient.dto';

export const confirmDosageDtoSchema = z
   .object({
      dosage: z
         .object({
            please_confirm_the_dose: z.coerce
               .number()
               .min(1, 'Dose is required'),
            date: dateTimeField,
            'what-is-the-package-lot-that-your-medicine-comes-from': z.string(),
         })
         .required(),
      bloodPressure: z
         .object({
            what_is_the_top_number_systol: z.coerce
               .number()
               .max(160, 'Top number must be less than 160'),
            what_is_the_bottom_number_dia: z.coerce
               .number()
               .max(100, 'Bottom number must be less than 100'),
         })
         .optional(),
      sitter: updateSitterDtoSchema
         .pick({
            name: true,
            phone: true,
            email: true,
            relationship: true,
            isEc: true,
         })
         .required(),
      emergencyContact: z.object({
         name: z.string().optional(),
         phone: z.string().optional(),
         email: z.string().optional(),
         relationship: z.string().optional(),
      }),
   })
   .superRefine(({ sitter, emergencyContact }, { addIssue }) => {
      const sitterIsEc = sitter.isEc;

      if (!sitterIsEc) {
         const code = 'custom';
         const { name, phone, email, relationship } = emergencyContact;

         if (!name) {
            addIssue({
               code,
               message: 'Emergency contact name is required.',
               path: ['emergencyContact.name'],
            });
         }
         if (!phone) {
            addIssue({
               code,
               message: 'Emergency contact phone is required.',
               path: ['emergencyContact.phone'],
            });
         }
         if (!email) {
            addIssue({
               code,
               message: 'Emergency contact email is required.',
               path: ['emergencyContact.email'],
            });
         }

         if (!relationship) {
            addIssue({
               code,
               message: 'Emergency contact relationship is required.',
               path: ['emergencyContact.relationship'],
            });
         }
      }
   });
export type ConfirmDosageDto = z.infer<typeof confirmDosageDtoSchema>;
