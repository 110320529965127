import { WelkinEncounterTemplateNames } from '../welkin';
import { z } from 'zod';

const booleanField = z
   .enum(['0', '1', 'true', 'false'])
   .catch('false')
   .transform((value) => value == 'true' || value == '1');

export const eligibleCliniciansParamsSchema = z.object({
   templateName: z.nativeEnum(WelkinEncounterTemplateNames),
   forInsurance: booleanField.optional(),
});

export type EligibleCliniciansParamsDto = z.infer<
   typeof eligibleCliniciansParamsSchema
>;
