import { BaseError, ErrorTypes } from '../../errors';

export abstract class SchedulingError extends BaseError {
   __schedulingError = true;

   toJSON() {
      return {
         __baseError: true,
         __schedulingError: this.__schedulingError,
         name: this.name,
         message: this.message,
         type: ErrorTypes.Scheduling,
      };
   }
}
