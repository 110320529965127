import { z } from 'zod';
import { WelkinEncounterTemplateNames } from '../welkin';
import { extendApi } from '@anatine/zod-openapi';

export const appointmentFromBankResponseDtoSchema = z.object({
   title: z.string(),
   templateName: z.nativeEnum(WelkinEncounterTemplateNames),
   inPersonAvailable: extendApi(z.boolean(), {
      description: `Whether the appointment is available in person and should be offered that option on the frontend. This is taken
      from the admin panel.`,
   }),
   purchasedCount: z.number().int(),
   usedCount: z.number().int(),
   paymentCdts: extendApi(z.array(z.string().uuid()), {
      description:
         'Payment CDT ids (cdt-patient-payment-information) that contributed to purchasedCount',
   }),
   schedulingBounds: extendApi(
      z
         .object({
            earliestStartDate: z.string().datetime().or(z.null()),
            latestEndDate: z.string().datetime().or(z.null()),
         })
         .or(z.null()),
      {
         description:
            'If present, appointment is schedulable within these bounds.',
      },
   ),
   schedulingHint: extendApi(z.string().or(z.null()), {
      description:
         'If appointment if not schedulable due to some reason, this field will contain the reason.',
   }),
   // number of encounters of that same type (templateName) that the patient has scheduled
   numOfEncounters: extendApi(z.number().int(), {
      description: `This info might be useful for recurring appointments, like Medical Follow-up`,
   }),
});

export type AppointmentFromBankResponseDto = z.infer<
   typeof appointmentFromBankResponseDtoSchema
>;
