import { SchedulingError } from './SchedulingErrorBase.error';

// This is also used when there is more than 1 program in progress.
export class NoPatientProgramInProgress extends SchedulingError {
   override name = 'scheduling.noPatientProgramInProgress';

   constructor() {
      super('No patient program in progress for patient.');
   }
}
