import { z } from 'zod';

export const marketingSearchCliniciansSchema = z.object({
   stateSlug: z.string(),
   serviceSlug: z.string(),
   insuranceSlug: z.string().optional(),
   treatmentMethodSlugs: z.array(z.string()).optional(),
});

export type MarketingSearchCliniciansDto = z.infer<
   typeof marketingSearchCliniciansSchema
>;
