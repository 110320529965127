import { z } from 'zod';
import { timetapStaffDateTimeSchema } from '../timetap';
import { type WelkinEncounterTemplateNames } from '../welkin';

export interface CreateAppointmentDto {
   appointmentTemplate: WelkinEncounterTemplateNames;
   startDateTime: string; // ISO8601, "2022-08-17T20:00:00.000Z"
   clinicianId: string;
}

export const createTimetapAppointmentSchema = z.object({
   serviceId: z.number(),
   locationId: z.number(),
   staffId: z.number(),
   // client: timetapClientDateTimeSchema,
   staff: timetapStaffDateTimeSchema.pick({
      startDateUtc: true,
      endDateUtc: true,
   }),
   forInsurance: z.boolean(),
});

export type CreateAppointmentTimetapDto = z.infer<
   typeof createTimetapAppointmentSchema
>;
