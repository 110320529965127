import { type AxiosError } from 'axios';
import _ from 'lodash';

export function getMessageFromAxiosError(err: AxiosError) {
   if (err.response && _.isObject(err.response.data)) {
      const data = err.response.data;
      if (Array.isArray(data)) {
         return (
            (_.isObject(data[0]) ? (data[0] as Error).message : undefined) ??
            err.message
         );
      }
      return (data as Error).message ?? err.message;
   }
   return err.message;
}
