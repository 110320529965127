import { z } from 'zod';
import {
   booleanExtendedField,
   intExtendedField,
   stringAmount,
} from '../../common';

export const updateSubscriptionPlanSchema = z.object({
   payments: intExtendedField,
   // each n months
   monthFrequency: intExtendedField.optional(),
   // each n days
   dayFrequency: intExtendedField.optional(),
   dayOfMonth: intExtendedField.optional(),
   amount: stringAmount.optional(),
});

export const updateSubscriptionSchema = z.object({
   isPaused: booleanExtendedField.optional(),
   plan: updateSubscriptionPlanSchema.optional(),
});

export type UpdateSubscriptionParamsDto = z.infer<
   typeof updateSubscriptionSchema
>;
