import { z } from 'zod';
import { jsonObjectSchema } from '../common';

export const webhookPayloadDtoSchema = z.object({
   event: z.string(),
   payload: jsonObjectSchema,
   event_ts: z.number(),
});

export type WebhookPayloadDto = z.infer<typeof webhookPayloadDtoSchema>;
