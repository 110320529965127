export type CreatePatientParams = {
   external_id: string;
   first_name: string;
   last_name: string;
   phone_number?: string;
   birth_date: string; // YYYY-MM-DD
};

export type CreatePrescriptionParams = {
   external_id: string;
   reference_id?: string;
   external_patient_id: string;

   // Doctor info required for controlled substances
   doctor_npi?: string;
   doctor_first_name?: string;
   doctor_last_name?: string;

   medication_id: string;
   quantity: number;
   instructions: string;
};

export type AssignIdToPrescriptionParams = {
   external_id: string;
   reference_id: string;
};

export type CancelPrescriptionParams = {
   prescription_external_id?: string;
   prescription_reference_id?: string;
};

export const FEDEX_SHIPPING_METHODS = {
   FirstOvernight: 'fedex_first_overnight',
   PriorityOvernight: 'fedex_priority_overnight',
   StandardOvernight: 'fedex_standard_overnight',
   TwoDay: 'fedex_2_day',
   TwoDayAm: 'fedex_2_day_am',
   ExpressSaver: 'fedex_express_saver',
} as const;

export type FedexShippingMethods =
   (typeof FEDEX_SHIPPING_METHODS)[keyof typeof FEDEX_SHIPPING_METHODS];

export const USPS_SHIPPING_METHODS = {
   FirstClassMailFlat: 'usps_first_class_mail_flat',
   FirstClassMailLetter: 'usps_first_class_mail_flat',
   First: 'usps_first',
   ParcelSelect: 'usps_parcel_select',
   Priority: 'usps_priority',
   PriorityExpress: 'usps_priority_express',
   MediaMail: 'usps_media_mail',
} as const;

export type UspsShippingMethods =
   (typeof USPS_SHIPPING_METHODS)[keyof typeof USPS_SHIPPING_METHODS];

export const UPS_SHIPPING_METHODS = {
   UpsSecondDayAirAm: 'ups_second_day_air_am',
   UpsSecondDayAir: 'ups_second_day_air',
   UpsNextDayAirSaver: 'ups_next_day_air_saver',
   UpsSecondDayAirSignature: 'ups_second_day_air_asr',
   UpsNextDayAirSaverSignature: 'ups_next_day_air_saver_asr',
} as const;

export const ALL_PRECISION_SHIPPING_METHODS = {
   ...UPS_SHIPPING_METHODS,
   ...USPS_SHIPPING_METHODS,
   ...FEDEX_SHIPPING_METHODS,
};

export type AllShippingMethods =
   (typeof ALL_PRECISION_SHIPPING_METHODS)[keyof typeof ALL_PRECISION_SHIPPING_METHODS];

const convertObjectToOptions = <T extends string | number | symbol>(
   obj: Record<string, T>,
) => {
   return Object.entries(obj).map((method) => ({
      label: method[0],
      value: method[1],
   }));
};

export const allShippingMethodOptions =
   convertObjectToOptions<AllShippingMethods>(ALL_PRECISION_SHIPPING_METHODS);

/**
 * These are the shipping methods that we will use by default. (Needed for the UI select)
 */
export const DEFAULT_SHIPPING_METHODS = UPS_SHIPPING_METHODS;
type DefaultShippingMethods =
   (typeof ALL_PRECISION_SHIPPING_METHODS)[keyof typeof ALL_PRECISION_SHIPPING_METHODS];

export const defaultShippingMethodOptions =
   convertObjectToOptions<DefaultShippingMethods>(DEFAULT_SHIPPING_METHODS);
export const DEFAULT_SHIPPING_METHOD =
   UPS_SHIPPING_METHODS.UpsSecondDayAirSignature;

export type UpsShippingMethods =
   (typeof UPS_SHIPPING_METHODS)[keyof typeof UPS_SHIPPING_METHODS];

export type ShippingMethods =
   | UspsShippingMethods
   | FedexShippingMethods
   | UpsShippingMethods;

export const CARRIERS = {
   Fedex: 'fedex',
   Usps: 'usps',
   Ups: 'ups',
} as const;

export const DEFAULT_CARRIER = CARRIERS.Ups;

export type Carrier = (typeof CARRIERS)[keyof typeof CARRIERS];

export const getShippingMethodOptionsFromCarrier = (carrier: Carrier) => {
   return Object.entries(ALL_PRECISION_SHIPPING_METHODS)
      .filter((method) => method[1].startsWith(carrier))
      .map((method) => ({
         label: method[0],
         value: method[1],
      }));
};

export type CreateFillParams = {
   refill_external_id?: string;
   refill_reference_id?: string;
   packaging_id?: string;
   shipping_address1: string;
   shipping_address2?: string;
   shipping_city: string;
   shipping_state: string; // 2-letter state code
   shipping_zip_code: string;

   prescriptions_reference_ids?: string[];
   prescriptions_external_ids?: string[];

   carrier: Carrier;
   shipping_method: ShippingMethods;

   item_ids?: string[];
   quantity?: number;

   internal_notes?: string;
};

export type GetPatientsParams = {
   external_id?: string;
   first_name?: string;
   last_name?: string;
};

export type CancelFillParams = {
   refill_reference_id: string;
};

export const ErrorCodesList = {
   // Patient errors
   DUPLICATE_ID: 'DUPLICATE_ID',
   PATIENT_REQUIRED: 'PATIENT_REQUIRED',
   WRONG_DATE_FORMAT: 'WRONG_DATE_FORMAT',
   WRONG_PHONE_FORMAT: 'WRONG_PHONE_FORMAT',
   FIELD_REQUIRED: 'FIELD_REQUIRED',
   UNKNOWN: 'UNKNOWN',

   // Prescription errors
   ID_REQUIRED: 'ID_REQUIRED',
   DOCTOR_REQUIRED: 'DOCTOR_REQUIRED',
   NO_MEDICATION_ADDED: 'NO_MEDICATION_ADDED',
   ITEM_NOT_FOUND: 'ITEM_NOT_FOUND',

   // Refill errors
   PRESCRIPTION_ID_REQUIRED: 'PRESCRIPTION_ID_REQUIRED',
   PRESCRIPTION_NOT_FOUND: 'PRESCRIPTION_NOT_FOUND',
   PRESCRIPTIONS_NOT_FOUND: 'PRESCRIPTIONS_NOT_FOUND',
   SHIPPING_METHOD_NOT_VALID: 'SHIPPING_METHOD_NOT_VALID',
   CARRIER_NOT_VALID: 'CARRIER_NOT_VALID',
   SHIPPING_ADDRESS_MISSING: 'SHIPPING_ADDRESS_MISSING',
   MANY_PATIENTS: 'MANY_PATIENTS',
   REFILL_ID_REQUIRED: 'REFILL_ID_REQUIRED',
   REFILL_NOT_FOUND: 'REFILL_NOT_FOUND',
   REFILL_ALREADY_SHIPPED: 'REFILL_ALREADY_SHIPPED',
   REFILL_ALREADY_CANCELED: 'REFILL_ALREADY_CANCELED',
   PRESCRIPTION_FLAGGED: 'PRESCRIPTION_FLAGGED',
   PRESCRIPTION_CANCELED: 'PRESCRIPTION_CANCELED',
} as const;

export type ErrorCodes = (typeof ErrorCodesList)[keyof typeof ErrorCodesList];

export type ApiResponseSuccess = {
   success: true;
   message?: string;
};

export type ApiResponseError = {
   success: false;
   message: string;
   errorCode: ErrorCodes;
};

export type ApiResponse = ApiResponseSuccess | ApiResponseError;

export const PrecisionWebhookTypes = {
   Received: 'received',
   Warning: 'warning',
   Shipped: 'shipped',
   EstimatedDelivery: 'estimated',
   Delivered: 'delivered',
} as const;

export type ReceivedWebhookDto = {
   test?: boolean;
   type: typeof PrecisionWebhookTypes.Received;
   external_id: string | null;
   reference_id: string | null;

   patient_first_name: string;
   patient_last_name: string;
   patient_birth_date: string;

   doctor_npi: string | null;
   doctor_first_name: string | null;
   doctor_last_name: string | null;
   doctor_address: string | null;

   medication_id: string;
};

export const WarningReasonCodes = {
   QuantityVerification: '1100', // prescription
   DrugVerification: '1020', // prescription
   DirectionVerification: '1030', // prescription
   PatientShippingAddressVerification: '1040', // fill
   PatientAllergiesNeeded: '1050', // prescription
   PatientNameClarification: '1060', // prescription
   PatientDOBClarification: '1070', // prescription
   DrugInteractionDetected: '1080', // prescription
   DuplicatePrescriptionReceived: '1090', // prescription
   StrengthVerification: '1100', // prescription
   DosageFormVerification: '1110', // prescription
   DeviceVerification: '1120', // prescription
   RequestShippingMethod: '1130', // fill
   ControlledSubstanceTooSoon: '1140', // prescription
   PrescriptionRequiresCode: '1150', // prescription
   FaxNotLegible: '1160', // prescription
   ProblemDeliveringPackage: '2010', // fill
} as const;

export type ReasonCode =
   (typeof WarningReasonCodes)[keyof typeof WarningReasonCodes];

export type WarningWebhookDto = {
   test?: boolean;
   type: typeof PrecisionWebhookTypes.Warning;
   event_for: 'prescription' | 'fill';
   prescription_external_id: string;
   prescription_reference_id: string;
   fill_external_id: string;
   fill_reference_id: string;

   /**
    * When the reason_code is 1140 (Controlled substance too soon), we will
    * send back the date that the prescription will be able to be filled.
    */
   first_fill_date?: string;
   // One of reason codes: https://precisioncompoundingpharmacy.net/docs/webhooks/
   reason_code: ReasonCode;
};

export type ShippedWebhookDto = {
   test?: boolean;
   type: typeof PrecisionWebhookTypes.Shipped;
   fill_external_id: string | null;
   fill_reference_id: string | null;

   // @deprecated
   prescription_external_id: string | null;
   // @deprecated
   prescription_reference_id: string | null;

   shipped_date: string; // 2023-07-20
   shipping_method: ShippingMethods;

   // multiple packages per order are possible
   total_packages: number;
   package_number: number;
   carrier: string;
   tracking_number: string;

   // Whether this is tracking number replacement or not
   replacement?: boolean;

   prescriptions_reference_ids?: string[];
   prescriptions_external_ids?: string[];
};

/**
 * From their docs: This event or web hook is called when the package HAS BEEN DELIVERED to the patient
 */
export type EstimatedDeliveryWebhookDto = {
   test?: boolean;
   type: typeof PrecisionWebhookTypes.EstimatedDelivery;
   fill_external_id: string | null;
   fill_reference_id: string | null;

   // @deprecated
   prescription_external_id: string | null;
   // @deprecated
   prescription_reference_id: string | null;

   // multiple packages per order are possible
   total_packages: number;
   package_number: number;
   carrier: string;
   tracking_number: string;
   estimated_delivery_date?: string; // 2023-07-20

   prescriptions_reference_ids?: string[];
   prescriptions_external_ids?: string[];
};

/**
 * From their docs: This event or web hook is called when the package HAS BEEN DELIVERED to the patient.
 * This event is sent for fills only.
 */
export type DeliveredWebhookDto = {
   test?: boolean;
   type: typeof PrecisionWebhookTypes.Delivered;
   fill_external_id: string | null;
   fill_reference_id: string | null;
   prescription_external_id: string | null;
   prescription_reference_id: string | null;
   delivered_date: string; // 2023-07-20

   prescriptions_reference_ids?: string[];
   prescriptions_external_ids?: string[];
};

export type PrecisionWebhookType =
   | DeliveredWebhookDto
   | EstimatedDeliveryWebhookDto
   | ShippedWebhookDto
   | WarningWebhookDto
   | ReceivedWebhookDto;

// If we don't omit 'type', then the resulting type is never
export type PrecisionWebhookTypeUnion = (Omit<DeliveredWebhookDto, 'type'> &
   Omit<EstimatedDeliveryWebhookDto, 'type'> &
   Omit<ShippedWebhookDto, 'type'> &
   Omit<WarningWebhookDto, 'type'> &
   Omit<ReceivedWebhookDto, 'type'>) & {
   type: 'delivered' | 'estimated' | 'shipped' | 'warning' | 'received';
};

export type PatientResponseDto = {
   id: string;
   account_id: string;
   external_id: string;
   first_name: string;
   last_name: string;
   birth_date: string; // format full utc with timestamp
   phone_number: string | null;
   status: 'active'; // @TODO: inactive, too?
   created_by_id: string | null;
   created_on: string;
   updated_by_id: string | null;
   updated_on: string;
   pharmacy_id: string | null;
};

export type GetPatientsResponseDto = {
   success: true;
   rows: PatientResponseDto[];
};

export type GetPatientByIdResponseDto = {
   success: true;
   patient: PatientResponseDto;
};
