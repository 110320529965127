import { type NmiMerchantFieldsParams } from './nmi-merchant-fields.schema';
import { z } from 'zod';

export enum NmiEventType {
   RecurringSubscriptionAdded = 'recurring.subscription.add',
   RecurringSubscriptionUpdated = 'recurring.subscription.update',
   RecurringSubscriptionDeleted = 'recurring.subscription.delete',

   RecurringPlanAdded = 'recurring.plan.add',
   RecurringPlanUpdated = 'recurring.plan.update',
   RecurringPlanDeleted = 'recurring.plan.delete',

   TransactionSaleSuccess = 'transaction.sale.success',
   TransactionSaleFailure = 'transaction.sale.failure',
   TransactionRefundSuccess = 'transaction.refund.success',
   TransactionVoidSuccess = 'transaction.void.success',

   TransactionAuthSuccess = 'transaction.auth.success',
   TransactionAuthFailure = 'transaction.auth.failure',

   TransactionCaptureSuccess = 'transaction.capture.success',
   TransactionCaptureFailure = 'transaction.capture.failure',

   TransactionValidateSuccess = 'transaction.validate.success',
   TransactionValidateFailure = 'transaction.validate.failure',

   SettlementBatchComplete = 'settlement.batch.complete',
   SettlementBatchFailure = 'settlement.batch.failure',
}

export interface NmiStandardEventBodyFields {
   merchant: {
      id: string;
      name: string;
   };
   features: Record<string, unknown>;
}

export interface NmiPlan {
   id: string;
   name: string;
   amount: string; // format "20.00"
   day_frequency: number | null;
   payments: number;
   month_frequency: number | null;
   day_of_month: number | null;
}

export interface NmiBillingAddress {
   first_name: string;
   last_name: string;
   address_1: string;
   address_2?: string;
   company: string;
   city: string;
   state: string;
   postal_code: string;
   country: string;
   email: string;
   phone: string;
   cell_phone: string;
   fax: string;
}

export type NmiShippingAddress = NmiBillingAddress;

export interface NmiCard {
   cc_number: string;
   cc_exp: string; // format 1022, ie MMYY
   cavv: string;
   cavv_result: string;
   xid: string;
   eci: string;
   avs_response: string;
   csc_response: string;
   cardholder_auth: string;
   cc_start_date: string;
   cc_issue_number: string;
   card_balance: string;
   card_available_balance: string;
   entry_mode: string;
   cc_bin: string;
   cc_type: string;
}

export const nmiProductSchema = z.object({
   sku: z.string(),
   quantity: z.string(),
   description: z.string(),
   amount: z.string(),
});
export type NmiProduct = z.infer<typeof nmiProductSchema>;

export interface NmiAction {
   amount: string;
   action_type: NmiActionType;
   date: string; // @NOTE: format is YYYYMMDDHHMMss (no separators)
   success: string;
   ip_address: string;
   source: NmiSource;
   api_method: string;
   username: string;
   response_text: string;
   response_code: string;
   processor_response_text: string;
   processor_response_code: string;
   device_license_number: string;
   device_nickname: string;
   tap_to_mobile: boolean;
}

export interface NmiWebhookEvent<T> {
   event_id: string;
   event_type: NmiEventType;
   event_body: NmiStandardEventBodyFields & T;
}

export enum NmiMerchantDefinedKeys {
   PatientId = '1',
   RetryTransactionId = '2',
   NoShowOrCancellation = '3',
   WelkinPaymentCdtId = '4',
   ChosenTherapistId = '5',
   Source = '6',
   ReceiptId = '7',
   InsurerName = '8',
   AppointmentDate = '9',
   CcLast4Digits = '10',
   PatientResponsibilityAmount = '11',
   FreeText = '12',
   FreeText2 = '13',
   PaymentType = '14',
   Options = '15',
   PaymentFailedCdtId = '16',
   MissedAppointmentCdtId = '17',
   EncounterId = '18',
}

export type NmiMerchantDefinedFields = {
   [key in NmiMerchantDefinedKeys]?: string;
};

export type NmiPaymentType = 'Copay';

export type NmiPaymentTypeOption = {
   value: NmiPaymentType;
   label: string;
};

export const NMI_OPTIONS_MAPPING = {
   NoPushToPaymentFailed: 'F',
   noPushToPaymentFailedOnRecurring: 'R',
   NoInvoice: 'I',
} as const;

export type NmiOptions = {
   noPushToPaymentFailed?: boolean;
   noPushToPaymentFailedOnRecurring?: boolean;
   noInvoice?: boolean;
};

// Result with ignoreAttributes: false when querying NMI
// Defined by fast-xml-parser
export type MerchantDefinedFieldResult = {
   '#text': string;
   '@_id': NmiMerchantDefinedKeys;
};

export type NmiMerchantFields = NmiMerchantFieldsParams;

/**
 * These enums are defined here:
 * https://usapayments.transactiongateway.com/merchants/resources/integration/integration_portal.php?TID=98a311f68a63b29f87765dfc985a336e#query_variables
 */
export enum NmiReportType {
   CustomerVault = 'customer_vault',
   Receipt = 'receipt',
   Recurring = 'recurring',
   RecurringPlans = 'recurring_plans',
   Invoicing = 'invoicing',
   GatewayProcessors = 'gateway_processors',
   AccountUpdater = 'account_updater',
   TestModeStatus = 'test_mode_status',

   AddBilling = 'add_billing',
   UpdateBilling = 'update_billing',
   DeleteBilling = 'delete_billing',
}

export enum NmiTransactionType {
   Sale = 'sale',
   Validate = 'validate',
   Capture = 'capture',
   Update = 'update',
   Auth = 'auth',
   Refund = 'refund',
   Void = 'void',
}

export enum NmiActionType {
   Sale = 'sale',
   Validate = 'validate',
   Refund = 'refund',
   Credit = 'credit',
   Auth = 'auth',
   Capture = 'capture',
   Void = 'void',
   Return = 'return',

   Settle = 'settle',
}

export enum NmiSource {
   Api = 'api',
   BatchUpload = 'batch_upload',
   Mobile = 'mobile',
   QuickClick = 'quickclick',
   QuickBooks = 'quickbooks',
   Recurring = 'recurring',
   Swipe = 'swipe',
   VirtualTerminal = 'virtual_terminal',
   Internal = 'internal',
}

export enum NmiTransactionChargeType {
   CreditCard = 'cc',
   Check = 'ck',
}

export enum NmiCondition {
   Pending = 'pending',
   PendingSettlement = 'pendingsettlement',
   InProgress = 'in_progress',
   Abandoned = 'abandoned',
   Failed = 'failed',
   // Triggered when voiding the transaction
   Canceled = 'canceled',
   Complete = 'complete',
   Unknown = 'unknown',
}

export enum TransactionResponse {
   Approved = '1',
   Declined = '2',
   Error = '3',
}

export type NmiTransactionLineItems = Partial<{
   [Key: `item_product_code_${number}`]: string;
   [Key: `item_description_${number}`]: string;
   [Key: `item_commodity_code_${number}`]: string;
   [Key: `item_unit_of_measure_${number}`]: string;
   [Key: `item_unit_cost_${number}`]: string;
   [Key: `item_quantity_${number}`]: string;
   [Key: `item_total_amount_${number}`]: string;
   [Key: `item_tax_amount_${number}`]: string;
   [Key: `item_tax_rate_${number}`]: string;
   [Key: `item_discount_amount_${number}`]: string;
   [Key: `item_discount_rate_${number}`]: string;
   [Key: `item_tax_type_${number}`]: string;
   [Key: `item_alternate_tax_id_${number}`]: string;
}>;

// https://usapayments.transactiongateway.com/merchants/resources/integration/integration_portal.php?tid=5acb7a5a521fba300bb90f9cccf39def#transaction_variables
export type NmiTransactionVariables = {
   type: NmiTransactionType;
   // | 'sale'
   // | 'auth'
   // | 'credit'
   // | 'offline'
   // | 'validate'
   // | 'void'
   // | 'refund'
   // | 'update';

   // if no payment_token
   ccnumber?: string;
   ccexp?: string;
   cvv?: string;

   amount?: string; // for validate it is omitted or set to 0.00
   currency?: string;

   orderid?: string;

   first_name?: string;
   last_name?: string;
   company?: string;
   address1?: string;
   address2?: string;
   city?: string;
   state?: string;
   zip?: string;
   country?: string;
   phone?: string;
   fax?: string;
   email?: string;

   source_transaction_id?: string;

   // @TODO: incorrect name
   discount?: string;

   shipping_firstname?: string;
   shipping_lastname?: string;
   shipping_company?: string;
   shipping_address1?: string;
   shipping_address2?: string;
   shipping_city?: string;
   shipping_state?: string;
   shipping_zip?: string;
   shipping_country?: string;
   shipping_email?: string;

   // disable duplicate checking if 0
   dup_seconds?: number;

   // void
   transactionid: string;

   customer_receipt: string;
} & NmiTransactionLineItems;

export type NmiCustomerVaultVariables = {
   customer_vault?: 'add_customer' | 'update_customer' | 'delete_customer';
   customer_vault_id?: string;
   billing_id?: string;
};

type MerchantDefinedFieldKeys =
   `merchant_defined_field_${keyof NmiMerchantDefinedFields}`;

export type NmiMerchantDefinedFieldVariables = {
   [Key in MerchantDefinedFieldKeys]?: string;
};

export type NmiCustomSubscriptionVariables = {
   plan_payments: string;
   plan_amount: string;
   day_frequency?: string;
   month_frequency?: string;
   day_of_month?: string;
};

export type NmiSubscriptionVariables = {
   recurring:
      | 'add_subscription'
      | 'update_subscription'
      | 'delete_subscription';
   start_date?: string; // YYYYMMDD
   plan_name?: string;
   plan_amount?: string;
   plan_payments?: string;
   subscription_id?: string; // required for update & delete
   paused_subscription?: string;
} & (
   | NmiCustomSubscriptionVariables
   | {
        // When using predefined plan
        plan_id: string;
     }
);

export type NmiTransactSharedParams = {
   security_key: string;
   payment_token?: string; // Collect.js token
};

export type NmiTransactParams = NmiTransactSharedParams &
   Partial<NmiTransactionVariables> &
   Partial<NmiCustomerVaultVariables> &
   Partial<NmiSubscriptionVariables> &
   NmiMerchantDefinedFieldVariables &
   NmiTransactionLineItems;
