import { NmiCondition } from './nmi.types';

export const NMI_COMPLETED_TRANSACTION_STATUSES =
   // eslint-disable-next-line turbo/no-undeclared-env-vars
   process.env.NMI_TREAT_PENDING_AS_COMPLETED === '1'
      ? [
           NmiCondition.Complete,
           // Added here since in test environment this is the farthest status/condition we're getting
           NmiCondition.PendingSettlement,
        ]
      : [NmiCondition.Complete];

export const FINAL_NMI_STATUSES = [
   ...NMI_COMPLETED_TRANSACTION_STATUSES,
   NmiCondition.Canceled,
];

// Includes Pending which is triggered by auth transaction
export const NMI_CONDITIONS_FOR_AUTH = [
   // Required for auth
   NmiCondition.Pending,
   // Sale, Capture
   NmiCondition.PendingSettlement,
   // This is only relevant for Validate transactions
   // Transaction is settled when settlement.batch.completed fires, and this is not event type that is handled here
   NmiCondition.Complete,
   // Voiding transaction
   NmiCondition.Canceled,
];
