import {
   type TimetapClientDateTime,
   timetapClientDateTimeSchema,
   type TimetapStaffDateTime,
   timetapStaffDateTimeSchema,
} from '../timetap';
import { type WelkinEncounterStatus } from '../welkin';
import { z } from 'zod';

export interface UpdateAppointmentDto {
   startDateTime?: string;
   status?: WelkinEncounterStatus.Cancelled;
}

export class UpdateAppointmentTimetapDto {
   client: TimetapClientDateTime;
   staff: TimetapStaffDateTime;
}
export const updateAppointmentTimetapSchema = z.object({
   client: timetapClientDateTimeSchema,
   staff: timetapStaffDateTimeSchema,
});
