import { MoodTrackingEnum } from './mood-tracking.enum';
import { z } from 'zod';
import { dateField } from '../common';

export const updateMoodTrackingDtoSchema = z.object({
   score: z.nativeEnum(MoodTrackingEnum), // number to enum
   details: z.string(),
   date: dateField,
});

export type UpdateMoodTrackingDto = z.infer<typeof updateMoodTrackingDtoSchema>;
