import { type MagentoProductTypeEnum } from '../magento';
import { type PaymentCode } from '../products';
import { z } from 'zod';

export const cartItemDtoSchema = z.object({
   sku: z.string(),
   qty: z.number().min(1),
   quoteId: z.union([z.string(), z.null()]).optional(),
});

export type CartItemDto = z.infer<typeof cartItemDtoSchema>;

export const addCartDtoSchema = z.object({
   cartId: z.string(),
   cartItem: cartItemDtoSchema,
});

export type AddCartDto = z.infer<typeof addCartDtoSchema>;

export const updateCartDtoSchema = z.object({
   itemId: z.number(),
   cartId: z.string(),
   cartItem: cartItemDtoSchema,
});

export type UpdateCartDto = z.infer<typeof updateCartDtoSchema>;

export interface InnerwellCartPaymentMethod {
   code: string;
   title: string;
}

export type InnerwellCartItem = {
   itemId: number;
   sku: string;
   qty: number;
   name: string;
   price: number;
   specialPrice: number;
   productType: MagentoProductTypeEnum;
   allowedPaymentMethods: PaymentCode[];
   isMagentoProduct: boolean;
   categoryIds: string[] | null | undefined;
};

type InnerwellCartTotalItem = {
   itemId: number;
   price: number;
   basePrice: number;
   qty: number;
   total: number;
   discount: number;
};

export type InnerwellCartPayment = {
   code: PaymentCode;
   frequency: PaymentCode;
   numOfPayments: string;
   title: string;
};

export type InnerwellCartTotals = {
   cartTotal: number;
   itemsQty: number;
   discountAmount: number;
   currencyCode: string;
   items: InnerwellCartTotalItem[];
   paymentMethod: InnerwellCartPayment;
};
