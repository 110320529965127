import {
   type TimetapStatus,
   type TimetapTimestapData as TimetapTimestampData,
} from './shared.types';
import { type TimetapTimezoneDto } from './timezone.dto';
import { z } from 'zod';
import { arrayOfNumbersField, booleanExtendedField } from '../common';

export enum TimetapLocationType {
   Physical = 'PHYSICAL',
   Virtual = 'VIRTUAL',
   Variable = 'VARIABLE',
}

export type TimetapLocationReportParams = {
   dateRangeType?: 'createdDate' | 'modifiedDate';

   endDate?: string; // format YYYY-MM-DD

   locationGroupId?: number;

   locationGroupIdList?: number[];

   locationIdList?: number[];

   locationIdListToExclude?: number[];

   locationTypeList?: TimetapLocationType[];

   pageNumber?: number;
   pageSize?: number;
   staffId?: number;
   staffIdList?: number[];
   startDate?: string;
   working?: boolean;

   state?: string;

   zipCodes?: string[];
};

export const timetapLocationsParamsSchema = z.object({
   state: z.string().optional(),
   staffId: z.number().optional(),
});

export type TimetapLocationsParamsDto = z.infer<
   typeof timetapLocationsParamsSchema
>;

export interface TimetapLocationResponseDto extends TimetapTimestampData {
   locationId: number;
   locationName: string;
   internalDisplayName: string;
   businessId: number;
   active: boolean;
   visible: boolean;
   description: string;
   address1: string | null;
   address2: string | null;
   city: string | null;
   state: string | null;
   zip: string | null;
   country: string | null;
   timeZone: TimetapTimezoneDto;
   locationType: TimetapLocationType;
   geocode: string | null;
   latitude: number | null;
   longitude: number | null;
   status: TimetapStatus;
   directions: string | null;
   // additional directions instructions
   instructions2: string | null;
}

export const timetapLocationsReportParamsSchema = z.object({
   staffIds: arrayOfNumbersField.optional(),
   working: booleanExtendedField.optional(),
   locationTypes: z.array(z.nativeEnum(TimetapLocationType)).optional(),
   state: z.string().nullable().optional(),
});

export type TimetapLocationsReportParamsDto = z.infer<
   typeof timetapLocationsReportParamsSchema
>;
