export interface UsState {
   abbr: string;
   title: string;
}

export const US_STATES = [
   { abbr: 'AK', title: 'Alaska' },
   { abbr: 'AL', title: 'Alabama' },
   { abbr: 'AR', title: 'Arkansas' },
   { abbr: 'AZ', title: 'Arizona' },
   { abbr: 'CA', title: 'California' },
   { abbr: 'CO', title: 'Colorado' },
   { abbr: 'CT', title: 'Connecticut' },
   { abbr: 'DC', title: 'District of Columbia' },
   { abbr: 'DE', title: 'Delaware' },
   { abbr: 'FL', title: 'Florida' },
   { abbr: 'GA', title: 'Georgia' },
   { abbr: 'HI', title: 'Hawaii' },
   { abbr: 'IA', title: 'Iowa' },
   { abbr: 'ID', title: 'Idaho' },
   { abbr: 'IL', title: 'Illinois' },
   { abbr: 'IN', title: 'Indiana' },
   { abbr: 'KS', title: 'Kansas' },
   { abbr: 'KY', title: 'Kentucky' },
   { abbr: 'LA', title: 'Louisiana' },
   { abbr: 'MA', title: 'Massachusetts' },
   { abbr: 'MD', title: 'Maryland' },
   { abbr: 'ME', title: 'Maine' },
   { abbr: 'MI', title: 'Michigan' },
   { abbr: 'MN', title: 'Minnesota' },
   { abbr: 'MO', title: 'Missouri' },
   { abbr: 'MS', title: 'Mississippi' },
   { abbr: 'MT', title: 'Montana' },
   { abbr: 'NC', title: 'North Carolina' },
   { abbr: 'ND', title: 'North Dakota' },
   { abbr: 'NE', title: 'Nebraska' },
   { abbr: 'NH', title: 'New Hampshire' },
   { abbr: 'NJ', title: 'New Jersey' },
   { abbr: 'NM', title: 'New Mexico' },
   { abbr: 'NV', title: 'Nevada' },
   { abbr: 'NY', title: 'New York' },
   { abbr: 'OH', title: 'Ohio' },
   { abbr: 'OK', title: 'Oklahoma' },
   { abbr: 'OR', title: 'Oregon' },
   { abbr: 'PA', title: 'Pennsylvania' },
   { abbr: 'RI', title: 'Rhode Island' },
   { abbr: 'SC', title: 'South Carolina' },
   { abbr: 'SD', title: 'South Dakota' },
   { abbr: 'TN', title: 'Tennessee' },
   { abbr: 'TX', title: 'Texas' },
   { abbr: 'UT', title: 'Utah' },
   { abbr: 'VA', title: 'Virginia' },
   { abbr: 'VT', title: 'Vermont' },
   { abbr: 'WA', title: 'Washington' },
   { abbr: 'WI', title: 'Wisconsin' },
   { abbr: 'WV', title: 'West Virginia' },
   { abbr: 'WY', title: 'Wyoming' },
] as const;

export type StateAbbrValue = (typeof US_STATES)[number]['abbr'];
export type StateTitleValue = (typeof US_STATES)[number]['title'];

export const stateTitles = US_STATES.map((state) => state.title);
export const stateAbbreviation = US_STATES.map((state) => state.abbr) as [
   StateAbbrValue,
   ...StateAbbrValue[],
];

export const US_STATES_MAP = new Map(
   US_STATES.map((state) => {
      return [state.abbr, state];
   }),
);

export function getStateFromAbbr(abbr: StateAbbrValue) {
   return US_STATES_MAP.get(abbr);
}

export function getStateFromTitle(title: string) {
   return US_STATES.find((state) => state.title === title);
}
