import type { Prisma } from '@innerwell/prisma-app-generated';
import { type GetFindResult } from '@prisma/client/runtime/library';

export const FILL_SELECT_FIELDS = {
   id: true,
   request: true,
   response: true,

   MedicationPrescriptionFillPrescription: {
      select: {
         medicationPrescriptionId: true,
         medicationPrescription: {
            select: {
               id: true,
               medicationName: true,
               drugNdcId: true,
               pharmacyName: true,
               patientName: true,
               patientId: true,
               createdAt: true,
               updatedAt: true,
            },
         },
      },
   },
} as const;

const args = {
   select: FILL_SELECT_FIELDS,
};

export type FillResponseDto = GetFindResult<
   Prisma.$MedicationPrescriptionFillPayload,
   typeof args,
   unknown
>;
