import { z } from 'zod';
import { dateTimeField } from '../common';

export type DosageConfirmationDto = {
   date: string;
   dosage: number;
   lot: string;
};

export const dosageConfirmationSchema = z.object({
   dateStart: dateTimeField,
   dateEnd: dateTimeField,
});

export type DosageConfirmationRequestDto = z.infer<
   typeof dosageConfirmationSchema
>;
