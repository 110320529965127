import { type AxiosError } from 'axios';
import { BaseError, ErrorTypes } from '../../errors';
import { getMessageFromAxiosError } from '../../errors/errors.utils';

// This is the actual error payload sent from the backend
export class MagentoError<T = unknown> extends BaseError {
   status: number | null;
   error: T | null;

   constructor(
      err: AxiosError<T>,
      public method: string,
   ) {
      super(getMessageFromAxiosError(err));

      this.type = ErrorTypes.Magento;

      // If response is not set, it isn't Magento error
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const response = err.response!;
      this.status = response.status;
      this.error = response.data;
   }

   toJSON() {
      return {
         __baseError: true,
         type: ErrorTypes.Magento,
         name: this.name,
         message: this.message,
         status: this.status,
         method: this.method,
         error: this.error,
      };
   }
}
