import { type PaymentCode, type PaymentFrequency } from '../products';
import { type MagentoProductTypeEnum } from './magento-product.dto';

export interface MagentoPaymentMethod {
   code: PaymentCode;
   title: string;
   frequency: PaymentFrequency | null;
   installments: string | null;
}

export type MagentoPaymentMethodsResponseDto = MagentoPaymentMethod[];

export type MagentoCreateCartResponseDto = string;

export type MagentoAddToCartResponseDto = {
   item_id: number;
   sku: string;
   qty: number;
   name: string;
   price: number;
   product_type: MagentoProductTypeEnum;
   quote_id: string;
};

export type MagentoCartPaymentMethodSetDto = {
   method: {
      method: string;
   };
};

export enum MagentoCurrencyCode {
   USD = 'USD',
}

export enum MagentoCountryId {
   US = 'US',
}

export enum MagentoAddressType {
   Billing = 'billing',
   Shipping = 'shipping',
}

export type MagentoCartTotalItem = {
   item_id: number;
   price: number;
   base_price: number;
   qty: number;
   row_total: number;
   base_row_total: number;
   row_total_with_discount: number;
   tax_amount: number;
   base_tax_amount: number;
   tax_percent: number;
   discount_amount: number;
   base_discount_amount: number;
   discount_percent: number;
   price_incl_tax: number;
   base_price_incl_tax: number;
   row_total_incl_tax: number;
   base_row_total_incl_tax: number;
   options: string;
   weee_tax_applied_amount: number;
   weee_tax_applied: number;
   name: string;
};

export type MagentoCartTotalSegment = {
   code: string;
   title: string;
   value: string;
};

export type MagentoCartTotalsResponseDto = {
   grand_total: number;
   base_grand_total: number;
   subtotal: number;
   base_subtotal: number;
   discount_amount: number;
   base_discount_amount: number;
   subtotal_with_discount: number;
   base_subtotal_with_discount: number;
   shipping_amount: number;
   base_shipping_amount: number;
   shipping_discount_amount: number;
   base_shipping_discount_amount: number;
   tax_amount: number;
   base_tax_amount: number;
   weee_tax_applied_amount: number;
   shipping_tax_amount: number;
   base_shipping_tax_amount: number;
   subtotal_incl_tax: number;
   base_subtotal_incl_tax: number;
   shipping_incl_tax: number;
   base_shipping_incl_tax: number;
   base_currency_code: MagentoCurrencyCode;
   quote_currency_code: MagentoCurrencyCode;
   items_qty: number;
   items: MagentoCartTotalItem[];
   total_segments: MagentoCartTotalSegment[];
};
