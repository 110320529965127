import { z } from 'zod';
import { booleanExtendedField } from '../common/zod.fields';

export const allowedFollowOnsParamsSchema = z.object({
   forInsurance: booleanExtendedField,
});

export type AllowedFollowOnsParams = z.infer<
   typeof allowedFollowOnsParamsSchema
>;
