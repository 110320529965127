import _ from 'lodash';

export const TransformArrayOfNumbers = (value: unknown) => {
   if (_.isString(value)) {
      return value.split(',').map((s) => Number(s));
   }
   if (_.isArray(value)) {
      return value.map((v) => Number(v));
   }
   return [];
};
export const TransformArrayOfStrings = (value: unknown) => {
   if (_.isString(value)) {
      return value.split(',').filter((s) => Boolean(s));
   }
   if (_.isArray(value)) {
      return value as unknown[];
   }
   return [];
};
