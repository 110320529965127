import { z } from 'zod';
import { ResendCodeType } from './resend-code.dto';

export const verifyUserAttributeSchema = z.object({
   type: z.nativeEnum(ResendCodeType),
   accessToken: z.string(),
   code: z.string(),
});

export type VerifyUserAttributeDto = z.infer<typeof verifyUserAttributeSchema>;
