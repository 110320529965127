// Check this reference: https://www.claim.md/eligibility_code_reference.pdf
// This might be newer: https://www.cms.gov/medicare/coding-billing/place-of-service-codes/code-sets
// Deductibles: https://www.healthcare.gov/glossary/deductible
// Out of pocket: https://www.healthinsurance.org/glossary/out-of-pocket-costs/

export enum ClaimMdPatientRelationshipCode {
   Self = '18',
   Dependent = 'G8',
}

export enum ClaimMdProviderTaxIdType {
   EIN = 'E',
   SSN = 'S',
}

export type ClaimMdGender = 'M' | 'F';

export type ClaimMdError = {
   error_code: string;
   error_mesg: string;
};

/**
 * W - Not Applicable
 * U - Unknown
 */
type ClaimMdYesNo = 'Y' | 'N' | 'W' | 'U';

type ClaimMdYesNoPayer = 'yes' | 'no';
type ClaimMdYesNoEnrollment = ClaimMdYesNoPayer | 'enrollment';

export type ClaimMdPayer = {
   attachment: ClaimMdYesNoEnrollment;
   workers_comp: ClaimMdYesNoPayer;
   payer_alt_names: (
      | {
           alt_payer_name: string;
        }
      | {
           alt_payerid: string;
        }
   )[];
   avg_era_enroll_days: string;
   '1500_claims': ClaimMdYesNoEnrollment;
   payer_name: string;
   ub_claims: ClaimMdYesNoEnrollment;
   dent_claims: ClaimMdYesNoEnrollment;
   payer_state: string;
   secondary_support: ClaimMdYesNoPayer;
   auto: ClaimMdYesNoPayer;
   eligibility: ClaimMdYesNoEnrollment;
   payer_type:
      | 'commercial'
      | 'medicare'
      | 'medicaid'
      | 'bcbs'
      | 'champus'
      | 'workerscomp';
   payerid: string;
   era: ClaimMdYesNoEnrollment;
};

export type ClaimMdPayersResponse = {
   payer: ClaimMdPayer[];
   error: ClaimMdError[];
};

export enum ClaimMdPlaceOfService {
   TelehealthNotInPatientHome = '02',
   TelehealthInPatientsHome = '10',
   Office = '11',
}

export enum BenefitCoverageCode {
   PrimaryCareProvider = 'L',
   OtherSourceOfData = 'W', // ???
   HealthCareFacility = 'X',
   ActiveCoverage = '1',
   InactiveCoverage = '6',
   NonCovered = 'I',
   Deductible = 'C',
   OutOfPocket = 'G',
   Limitations = 'F',
   Unlimited = 'H',
   CoInsurance = 'A',
   CoPayment = 'B',
   BenefitDisclaimer = 'P',
   OtherOrAdditionalPayor = 'R',
   ContactFollowingEntityForBenefitInformation = 'U',
   CannotProcess = 'V',
}

export enum BenefitLevelCode {
   EmployeeOnly = 'EMP',
   EmployeeAndFamily = 'FAM',
   EmployeeAndSpouse = 'ESP',
   EmployeeAndChildren = 'ECH',
   DependensOnly = 'DEP',
   ChildrenOnly = 'CHD',
   SpouseAndChildren = 'SPC',
   SpouseOnly = 'SPO',
   Individual = 'IND',
}

// Incomplete list, using only what we need
export enum BenefitTypeCode {
   HealthBenefitPlanCoverage = '30',
   MentalHealth = 'MH',
   MentalHealthProviderOutpatient = 'CF',
   Psychotherapy = 'A6',
   PsychiatricOutpatient = 'A8',
   ProfessionalVisitOffice = '98',
}

// export const BenefitLevelCode = {
//    EmployeeOnly: 'EMP',
//    EmployeeAndFamily: 'FAM',
//    Individual: 'IND',
// } as const;

// export type BenefitLevelCodeType =
//    (typeof BenefitLevelCode)[keyof typeof BenefitLevelCode];

export enum BenefitPeriodCode {
   Year = '23',
   Contract = '25',
   Remaining = '29',
   Lifetime = '32',
}

export enum InsuranceTypeCode {
   MedicareSecondaryWorking = '12',
   MedicareSecondaryEndStageRenal = '13',
   MedicareSecondaryNoFault = '14',
   MedicareSecondaryWorkersCompensation = '15',
   MedicareSecondaryPHS = '16',
   MedicareSecondaryBlackLung = '41',
   MedicareSecondaryVeteransAdministration = '42',
   MedicareSecondaryDisabledBeneficiaryUnderAge65 = '43',
   MedicareSecondaryOtherIsPrimary = '47',

   Commercial = 'C1',
   ExclusiveProviderOrganizationPlan = 'EP',
   HighDeductibleHealthPlan = 'HD',

   GroupPolicy = 'GP',

   MedicareConditionallyPrimary = 'CP',
   Disability = 'D',
   DisabilityBenefits = 'DB',
   HealthMaintenanceOrganization = 'HM',
   HealthMaintenanceOrganizationMedicareRisk = 'HN',
   SpecialLowIncomeMedicareBeneficiary = 'HS',

   Indemnity = 'IN',
   IndividualPolicy = 'IP',

   MedicarePartA = 'MA',
   MedicarePartB = 'MB',
   Medicaid = 'MC',
   MedigapPartA = 'MH',
   MedigapPartB = 'MI',
   MedicarePrimary = 'MP',

   Other = 'O',

   QualifiedMedicareBeneficiary = 'QM',

   PreferredProviderOrganizationPlan = 'PR',
   PointOfServicePlan = 'PS',
}

export type ClaimMdBenefitCoInsurance = {
   benefit_coverage_code: BenefitCoverageCode.CoInsurance;
   benefit_percent: number;
   benefit_description: string;
   benefit_code: BenefitTypeCode;
   benefit_level_code: BenefitLevelCode;
   benefit_level_description: string;
   benefit_notes: string;
   inplan_network: ClaimMdYesNo | 'W'; // not sure about W, but it's in the response
   place_of_service?: ClaimMdPlaceOfService;
};

export type ClaimMdBenefitCoPay = {
   benefit_coverage_code: BenefitCoverageCode.CoPayment;
   // @NOTE: coinsurance percent is number, but this one is string
   benefit_amount: string;
   benefit_description: string;
   benefit_code: BenefitTypeCode;
   benefit_level_code: BenefitLevelCode;
   benefit_level_description: string;
   benefit_notes: string;
   inplan_network: ClaimMdYesNo | 'W'; // not sure about W, but it's in the response
   place_of_service?: ClaimMdPlaceOfService;
};

export type ClaimMdBenefitCoPayProcessed = Omit<
   ClaimMdBenefitCoPay,
   'benefit_amount'
> & {
   benefit_amount: number;
};

export type ClaimMdBenefitOutOfPocket = {
   benefit_coverage_code: BenefitCoverageCode.OutOfPocket;
   benefit_period_code: BenefitPeriodCode;
} & Omit<ClaimMdBenefitCoPay, 'benefit_coverage_code'>;

export type ClaimMdBenefitDeductible = {
   benefit_coverage_code: BenefitCoverageCode.Deductible;
   benefit_period_code: BenefitPeriodCode;
   benefit_period_description: string;
} & Omit<ClaimMdBenefitCoPay, 'benefit_coverage_code'>;

export type ClaimMdEntityFields = {
   entity_city?: string[];
   entity_state?: string[];
   entity_zip?: string[];
   entity_addr_1?: string[];
   entity_description?: string[];
   entity_name?: string[];
   entity_code?: string[];
   entity_phone?: string[];
};

export type ClaimMdBenefitActiveCoverage = ClaimMdEntityFields & {
   benefit_notes?: string;
   benefit_coverage_code: BenefitCoverageCode.ActiveCoverage;
   benefit_level_code: BenefitLevelCode;
   benefit_description: string;
   benefit_code: BenefitTypeCode;

   // sometimes primary care provider is sent for benefit type code '30'
   insurance_type_code?: InsuranceTypeCode;
   insurance_type_description?: string;
   insurance_plan?: string;
};

export type ClaimMdBenefitNonCovered = {
   benefit_coverage_code: BenefitCoverageCode.NonCovered;
   benefit_level_code: BenefitLevelCode;
   inplan_network: ClaimMdYesNo | 'W'; // not sure about W, but it's in the response
   benefit_description: string;
   benefit_code: BenefitTypeCode;
};

export type ClaimMdBenefitPrimaryCareProvider = ClaimMdEntityFields & {
   benefit_coverage_code: BenefitCoverageCode.PrimaryCareProvider;
   benefit_level_code: BenefitLevelCode;
   benefit_code: BenefitTypeCode;
   benefit_level_description: string;
   insurance_type_description: string;
   insurance_type_code: InsuranceTypeCode;
   benefit_description: string;
};

export type ClaimMdBenefitUnlimited = {
   benefit_coverage_code: BenefitCoverageCode.Unlimited;
   benefit_level_code: BenefitLevelCode;
   benefit_period_code: BenefitPeriodCode;
   benefit_code: BenefitTypeCode;
   benefit_level_description: string;
   benefit_description: string;
};

export type ClaimMdBenefitLimitations = {
   benefit_coverage_code: BenefitCoverageCode.Limitations;
   benefit_notes: string;
   inplan_network: ClaimMdYesNo | 'W'; // not sure about W, but it's in the response
   benefit_code: BenefitTypeCode;
   benefit_level_code: BenefitLevelCode;
   benefit_description: string;
};

export type ClaimMdBenefitOtherSourceOfData = ClaimMdEntityFields & {
   benefit_coverage_code: BenefitCoverageCode.OtherSourceOfData;

   benefit_description: string;
   benefit_coverage_description: string;
   // not existing for this type
   benefit_code: undefined;
   benefit_level_code: undefined;
};

export type ClaimMdBenefitDisclaimer = {
   benefit_coverage_code: BenefitCoverageCode.BenefitDisclaimer;
   benefit_notes: string;
   benefit_description: string;
   benefit_coverage_description: string;
   // not existing for this type
   benefit_code: undefined;
   benefit_level_code: undefined;
};

// entity fields sometimes present
// Cigna - yes; no note, just entity fields
// BlueShield CA - no, but message is
//   MEMBERS BENEFIT PLAN ONLY COVERS URGENT AND EMERGENT SERVICES OUTSIDE OF
//   MEMBER S CONTRACTED NETWORK   IF SERVICE RENDERED IS NOT DEEMED URGENT OR EMERGENT
//   SERVICES MAY NOT BE COVERED
// so it doesn't make sense to include entity fields here.
export type ClaimMdBenefitContactFollowingEntityForBenefitInformation =
   ClaimMdEntityFields & {
      benefit_coverage_code: BenefitCoverageCode.ContactFollowingEntityForBenefitInformation;

      benefit_notes?: string;
      benefit_description: string;
      benefit_coverage_description: string;
      // not existing for this type
      benefit_code: undefined;
      benefit_level_code: undefined;
   };

// Found in BlueShield CA
export type ClaimMdBenefitHealthCareFacility = ClaimMdEntityFields & {
   benefit_coverage_code: BenefitCoverageCode.HealthCareFacility;
   benefit_description: string;
   benefit_code: BenefitTypeCode;
   benefit_code_description: string;
};

export type ClaimMdBenefitInactiveCoverage = {
   benefit_coverage_code: BenefitCoverageCode.InactiveCoverage;
   benefit_code: BenefitTypeCode;
   // todo
};

export type ClaimMdBenefit =
   | ClaimMdBenefitCoInsurance
   | ClaimMdBenefitCoPay
   | ClaimMdBenefitOutOfPocket
   | ClaimMdBenefitDeductible
   | ClaimMdBenefitActiveCoverage
   | ClaimMdBenefitNonCovered
   | ClaimMdBenefitPrimaryCareProvider
   | ClaimMdBenefitLimitations
   | ClaimMdBenefitUnlimited
   | ClaimMdBenefitOtherSourceOfData
   | ClaimMdBenefitDisclaimer
   | ClaimMdBenefitContactFollowingEntityForBenefitInformation
   | ClaimMdBenefitHealthCareFacility
   | ClaimMdBenefitInactiveCoverage;

export function isPrimaryCareProvider(
   item: ClaimMdBenefit,
): item is ClaimMdBenefitPrimaryCareProvider {
   return (
      item.benefit_coverage_code === BenefitCoverageCode.PrimaryCareProvider
   );
}

export function isCoInsuranceBenefitItem(
   item: ClaimMdBenefit,
): item is ClaimMdBenefitCoInsurance {
   return item.benefit_coverage_code === BenefitCoverageCode.CoInsurance;
}

export function isCoPayBenefitItem(
   item: ClaimMdBenefit,
): item is ClaimMdBenefitCoPay {
   return item.benefit_coverage_code === BenefitCoverageCode.CoPayment;
}

export function isActiveCoverageBenefitItem(
   item: ClaimMdBenefit,
): item is ClaimMdBenefitActiveCoverage {
   return item.benefit_coverage_code === BenefitCoverageCode.ActiveCoverage;
}

export function isInactiveCoverageBenefitItem(
   item: ClaimMdBenefit,
): item is ClaimMdBenefitInactiveCoverage {
   return item.benefit_coverage_code === BenefitCoverageCode.InactiveCoverage;
}

export function isPrimaryCareProviderBenefitItem(
   item: ClaimMdBenefit,
): item is ClaimMdBenefitPrimaryCareProvider {
   return (
      item.benefit_coverage_code === BenefitCoverageCode.PrimaryCareProvider
   );
}

export function isLimitationBenefitItem(
   item: ClaimMdBenefit,
): item is ClaimMdBenefitLimitations {
   return item.benefit_coverage_code === BenefitCoverageCode.Limitations;
}

export function isDeductibleBenefitItem(
   item: ClaimMdBenefit,
): item is ClaimMdBenefitDeductible {
   return item.benefit_coverage_code === BenefitCoverageCode.Deductible;
}

export function isNonCoveredBenefitItem(
   item: ClaimMdBenefit,
): item is ClaimMdBenefitNonCovered {
   return item.benefit_coverage_code === BenefitCoverageCode.NonCovered;
}

export function isInNetworkPlan<
   T extends {
      inplan_network: ClaimMdYesNo | 'W';
   },
>(item: T) {
   return item.inplan_network === 'Y';
}

export function isOutNetworkPlan<
   T extends {
      inplan_network: ClaimMdYesNo | 'W';
   },
>(item: T) {
   return item.inplan_network === 'Y';
}

// Insured person data. When dependent is checked, pat_* fields are returned for them and ins_* fields contain
// person how owns the insurance.
export type ClaimMdInsuredFields = {
   ins_name_f: string;
   ins_name_l: string;
   ins_number: string;
   ins_dob?: string | undefined;
   ins_sex?: ClaimMdGender;
   ins_addr_1?: string;
   ins_state?: string;
   ins_zip?: string;
   ins_city?: string;
};

// These fields are related to a dependent
export type ClaimMdPatientFields = {
   pat_name_f?: string;
   pat_name_l?: string;
   pat_dob?: string;
   pat_sex?: ClaimMdGender;
   pat_addr_1?: string;
   pat_city?: string;
   pat_zip?: string;
   pat_state?: string;
};

export type ClaimMdEligibility = ClaimMdInsuredFields &
   ClaimMdPatientFields & {
      elig_result_date: string;
      elig_result_time: string;
      eligid: string;
      eligibility_begin_date?: string;
      service_date: string;
      group_number: string;
      group_name: string;
      // might be in format <date> or <date>-<date>
      plan_begin_date?: string;
      // probably only in <date> format
      plan_end_date?: string;

      error?: ClaimMdError[];
      benefit?: ClaimMdBenefit[];
   };

export type ClaimMdEligibilityResponse = {
   elig?: ClaimMdEligibility;
   error?: ClaimMdError;
};

export const getItemsOfType = <T extends ClaimMdBenefit>(
   general: ClaimMdEligibilityResponse,
   specific: ClaimMdEligibilityResponse | null,
   fn: (item: ClaimMdBenefit) => item is T,
): T[] => {
   const specificItems = specific?.elig?.benefit?.filter(fn);

   if (specificItems?.[0]) {
      return specificItems;
   }

   return general.elig?.benefit?.filter(fn) ?? [];
};
