import { type WelkinPrograms } from '../../patients';
import { SchedulingError } from './SchedulingErrorBase.error';

export class NoSchedulingRulesForProgramError extends SchedulingError {
   override name = 'scheduling.noSchedulingRules';

   constructor(private program: WelkinPrograms) {
      super(`No scheduling rules for program ${program}`);
   }
}
