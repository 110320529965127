import { z } from 'zod';

import { nmiMerchantFieldsSchema } from './nmi-merchant-fields.schema';
import { nmiProductSchema } from './nmi.types';

const productSchema = nmiProductSchema.extend({
   unit: z.string().default('EACH'),
   discountAmount: z.number().optional(),
});

export type UpdateTransactionProductDto = z.infer<typeof productSchema>;

export const updateTransactionParamsSchema = z.object({
   products: z.array(productSchema).optional(),
   merchantDefinedFields: nmiMerchantFieldsSchema.optional(),
   orderId: z.string().optional(),
});

export type UpdateTransactionParamsDto = z.infer<
   typeof updateTransactionParamsSchema
>;
