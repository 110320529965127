import { z } from 'zod';
import { phoneField, stateField } from '../../common';

export const signupSchema = z.object({
   firstName: z.string().min(1),
   lastName: z.string().min(1),
   state: stateField,
   email: z.string().min(1).email(),
   phone: phoneField,
   password: z.string().min(1),
   formsortUUID: z.string().uuid().optional(),
   timezone: z.string().min(1),
});

export type SignupRequestDto = z.infer<typeof signupSchema>;
