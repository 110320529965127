import { z } from 'zod';
import { dateTimeISO8601Field } from '../common';

export const createMeetingDtoSchema = z.object({
   host: z.string().email(),
   topic: z.string(),
   startTime: dateTimeISO8601Field,
   timezone: z.string(),
   durationMinutes: z.number().optional(),
   privateMeeting: z.boolean().optional(),
});

export type CreateMeetingDto = z.infer<typeof createMeetingDtoSchema>;
