import { type MoodTrackingResultDto } from './mood-tracking-result.dto';
import { MoodTrackingRangeEnum } from './mood-tracking.enum';
import { z } from 'zod';
import { dateField, intExtendedField } from '../common';

export interface ProgressDto {
   segments: MoodTrackingResultDto[];
   sendWeeklyPause?: boolean;
   patientTimezone: string;
   isFirstMoodTracking?: boolean;
}

export interface WeeklyPauseInfoDto {
   phq9: boolean;
   gad7: boolean;
   pcl5: boolean;
}

export const rangeParamSchema = z.object({
   dateStart: dateField,
   dateEnd: dateField,
   period: z.nativeEnum(MoodTrackingRangeEnum),
});

export type RangeParamsDto = z.infer<typeof rangeParamSchema>;

export const cursorPaginationQueryParamsSchema = z.object({
   size: intExtendedField.optional(),
   page: intExtendedField.optional(),
});

export type CursorPaginationQueryParamsDto = z.infer<
   typeof cursorPaginationQueryParamsSchema
>;
