import { z } from 'zod';
import { dateTimeISO8601Field } from '../common';

export const updateMeetingDtoSchema = z.object({
   startTime: dateTimeISO8601Field,
   timezone: z.string(),
   durationMinutes: z.number().int(),
});

export type UpdateMeetingDto = z.infer<typeof updateMeetingDtoSchema>;
