import { SchedulingError } from './SchedulingErrorBase.error';

export class EncounterMissingError extends SchedulingError {
   override name = 'scheduling.encounterMissing';

   constructor(
      public missingEncounter: string,
      public schedulingEncounter: string,
      public requiredCount: number,
      // count of the appointment that we want to schedule
      // 1-based
      public currentEncounterIndex: number,
      public onlyCompletedConsidered: boolean,
   ) {
      super(
         `Expected ${missingEncounter}${
            requiredCount > 1 ? ` #${requiredCount.toString()}` : ''
         } to be${onlyCompletedConsidered ? ' completed' : ' completed or scheduled'} when scheduling ${schedulingEncounter}${
            currentEncounterIndex > 1
               ? ` #${currentEncounterIndex.toString()}`
               : ''
         }`,
      );
   }
}
