import _ from 'lodash';
import { type InvalidJWTDto } from './invalid-jwt.dto';
import { type TokenNotFoundErrorDto } from './token-not-found-error.dto';
import { type ValidationErrorDto } from './validation-error.dto';
import { WelkinErrorCodes } from './welkin-error-codes.enum';

// Expecting data from axios response, an array is always returned
export function isWelkinValidationErrorPayload(
   data: unknown,
): data is ValidationErrorDto[] {
   if (Array.isArray(data)) {
      return (
         data.length === 1 &&
         _.isObject(data[0]) &&
         (data[0] as ValidationErrorDto).errorCode ===
            WelkinErrorCodes.FieldsValidationError
      );
   }
   return false;
}

export function isWelkinTokenNotFoundErrorPayload(
   data: unknown,
): data is TokenNotFoundErrorDto[] {
   if (Array.isArray(data)) {
      return (
         data.length === 1 &&
         _.isObject(data[0]) &&
         (data[0] as TokenNotFoundErrorDto).errorCode ===
            WelkinErrorCodes.TokenNotFound
      );
   }
   return false;
}

export function isWelkinInvalidJWTErrorPayload(
   data: unknown,
): data is InvalidJWTDto[] {
   if (Array.isArray(data)) {
      return (
         data.length === 1 &&
         _.isObject(data[0]) &&
         (data[0] as InvalidJWTDto).errorCode === WelkinErrorCodes.InvalidJWT
      );
   }
   return false;
}
