import { z } from 'zod';

export enum FacebookConversionEvents {
   Purchase = 'Purchase',

   // For testing purposes
   TestEvent = 'TestEvent',
}

export type PurchaseEventContents = {
   id: string;
   quantity: number;
};

export type FacebookConversionEventMap = {
   [FacebookConversionEvents.Purchase]: {
      contents: PurchaseEventContents[];
      custom_data: {
         value: number;
         currency: string;
      };
   };
};

export type FacebookBaseParams<EventType> = {
   event_name: EventType;
   data: EventType extends keyof FacebookConversionEventMap
      ? FacebookConversionEventMap[EventType]
      : null;
   test_event_code?: string;
};

export type FacebookConversionBody<EventType> =
   FacebookBaseParams<EventType> & {
      event_time: number;
      action_source: string;
      user_data: {
         em: string;
         ph: string;
         fbc: string;
         fbp: string;
      };
   };

const facebookConversionEventMapSchema = z.object({
   contents: z.array(
      z.object({
         id: z.string(),
         quantity: z.number(),
      }),
   ),
   custom_data: z.object({
      value: z.number(),
      currency: z.string(),
   }),
});

const eventsSchema = z.union([
   z.object({
      event_name: z.literal(FacebookConversionEvents.Purchase),
      data: facebookConversionEventMapSchema,
   }),
   z.object({
      event_name: z.literal(FacebookConversionEvents.TestEvent),
   }),
]);

export const facebookBaseParamsSchema = z.intersection(
   eventsSchema,
   z.object({
      test_event_code: z.string().optional(),
   }),
);
