import {
   IntakeProgramPhases,
   ExtendedProgramPhases,
   FoundationProgramPhases,
   type WelkinProgramPhases,
   FollowOnProgramPhases,
   TherapyProgramPhases,
   GeneralPsychiatryProgramPhases,
   WelkinPrograms,
} from '@innerwell/dtos';

const isWelkinProgramAfterOrEqual = <T>(
   phase: T | null,
   reference: T | null,
   program: T[],
) => {
   // Initially phases is null so we return
   if (!phase || !reference) {
      return false;
   }

   const phaseIndex = program.indexOf(phase);
   const referenceIndex = program.indexOf(reference);

   // If reference is not found in IntakeProgramPhases, we return false
   // meaning the program is not IntakeProgramPhases
   if (referenceIndex === -1) {
      return false;
   }

   return phaseIndex >= referenceIndex;
};

const isWelkinProgramBefore = <T>(
   phase: T | null,
   reference: T | null,
   program: T[],
) => {
   // Initially phases is null so we return
   if (!phase || !reference) {
      return false;
   }

   const phaseIndex = program.indexOf(phase);
   const referenceIndex = program.indexOf(reference);

   // If reference is not found in IntakeProgramPhases, we return false
   // meaning the program is not IntakeProgramPhases
   if (referenceIndex === -1) {
      return false;
   }

   return phaseIndex < referenceIndex;
};

export const isIntakeProgramAfterOrEqual = (
   phase: WelkinProgramPhases | null,
   reference: IntakeProgramPhases | null,
) =>
   isWelkinProgramAfterOrEqual(
      phase,
      reference,
      Object.values(IntakeProgramPhases),
   );

export const isTherapyProgramAfterOrEqual = (
   phase: WelkinProgramPhases | null,
   reference: TherapyProgramPhases | null,
) =>
   isWelkinProgramAfterOrEqual(
      phase,
      reference,
      Object.values(TherapyProgramPhases),
   );

export const isGeneralPsychiatryProgramAfterOrEqual = (
   phase: WelkinProgramPhases | null,
   reference: GeneralPsychiatryProgramPhases | null,
) =>
   isWelkinProgramAfterOrEqual(
      phase,
      reference,
      Object.values(GeneralPsychiatryProgramPhases),
   );

export const isFollowOnProgramAfterOrEqual = (
   phase: WelkinProgramPhases | null,
   reference: FollowOnProgramPhases | null,
) =>
   isWelkinProgramAfterOrEqual(
      phase,
      reference,
      Object.values(FollowOnProgramPhases),
   );
export const isFollowOnProgramBefore = (
   phase: WelkinProgramPhases | null,
   reference: FollowOnProgramPhases | null,
) =>
   isWelkinProgramBefore(
      phase,
      reference,
      Object.values(FollowOnProgramPhases),
   );

export const isExtendedProgramAfterOrEqual = (
   phase: WelkinProgramPhases | null,
   reference: ExtendedProgramPhases | null,
) =>
   isWelkinProgramAfterOrEqual(
      phase,
      reference,
      Object.values(ExtendedProgramPhases),
   );

export const isExtendedProgramBefore = (
   phase: WelkinProgramPhases | null,
   reference: ExtendedProgramPhases | null,
) =>
   isWelkinProgramBefore(
      phase,
      reference,
      Object.values(ExtendedProgramPhases),
   );

export const isFoundationProgramBefore = (
   phase: WelkinProgramPhases | null,
   reference: FoundationProgramPhases | null,
) =>
   isWelkinProgramBefore(
      phase,
      reference,
      Object.values(FoundationProgramPhases),
   );

export const isFoundationProgramAfterOrEqual = (
   phase: WelkinProgramPhases | null,
   reference: FoundationProgramPhases | null,
) =>
   isWelkinProgramAfterOrEqual(
      phase,
      reference,
      Object.values(FoundationProgramPhases),
   );

export const isGeneralPsychiatryProgram = (program: WelkinPrograms | null) => {
   return program === WelkinPrograms.GeneralPsychiatry;
};

export const isTherapyProgram = (program: WelkinPrograms | null): boolean => {
   return !!program && [WelkinPrograms.Therapy].includes(program);
};
