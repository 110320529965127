import { z } from 'zod';
import { arrayOfNumbersField } from '../common';

export const addonsQueryDtoSchema = z.object({
   filters: z
      .object({
         tags: arrayOfNumbersField.optional(),
         state: z.string().optional(),
      })
      .optional(),
});

export type AddonsQueryDto = z.infer<typeof addonsQueryDtoSchema>;
