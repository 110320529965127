import { z } from 'zod';

export const confirmForgotPasswordSchemaDto = z.object({
   username: z.string(),
   code: z.string(),
   password: z.string(),
});

export type ConfirmForgotPasswordDto = z.infer<
   typeof confirmForgotPasswordSchemaDto
>;
