import { z } from 'zod';

export const updateServiceProviderLocationSchema = z
   .object({
      clinicianId: z.string().min(1, { message: 'Clinician ID is required' }),
      stateId: z.string().min(1, { message: 'State ID is required' }),
      encounters: z.array(
         z.object({
            encounterId: z.string(),
            welkinEncounterName: z.string(),
            enabled: z.coerce.boolean(),
         }),
      ),
   })
   .required();

export type UpdateServiceProviderLocationDto = z.infer<
   typeof updateServiceProviderLocationSchema
>;
