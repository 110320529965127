import { type ErrorTypes } from './error-types';

export interface BaseErrorResponse {
   __baseError: boolean;
   type: ErrorTypes;
   message: string;
}

export abstract class BaseError extends Error {
   __baseError: true;
   type: ErrorTypes;
   override message: string;

   abstract toJSON(): {
      __baseError: boolean;
      message: string;
      type: ErrorTypes;
   };
}

export function isBaseError(err: unknown): err is BaseErrorResponse {
   return (err as BaseErrorResponse)?.__baseError ?? false;
}
