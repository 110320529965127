export class TimezoneError extends Error {
   constructor(
      public patientId: string,
      public state: string,
   ) {
      super(
         `Patient ${patientId} state ${state} doesn't have timezone information associated with it`,
      );
   }
}
