declare module 'luxon' {
   interface TSSettings {
      throwOnInvalid: true;
   }
}

import { Settings } from 'luxon';
Settings.throwOnInvalid = true;

export * from './lib/utils';
export * from './lib/phase-utils';
export * from './lib/welkin';
export type * from './lib/ts-helpers';
