import { SCHEDULING_ALLOWED_RESCHEDULING_BEFORE_APPOINTMENT_HOURS } from '../scheduling.const';
import { SchedulingError } from './SchedulingErrorBase.error';

export class ReschedulingNotAllowedError extends SchedulingError {
   override name = 'scheduling.reschedulingNotAllowed';

   constructor() {
      super(
         `You can only reschedule if there's at least ${SCHEDULING_ALLOWED_RESCHEDULING_BEFORE_APPOINTMENT_HOURS.toString()} hours before the start of the appointment.`,
      );
   }
}
