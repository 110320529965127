import { z } from 'zod';

export enum CancelAppointmentIntent {
   WithoutFee = 'without-fee',
   WithFee = 'with-fee',
}

export const cancelAppointmentSchema = z.object({
   encounterId: z.string().uuid(),
   intent: z.nativeEnum(CancelAppointmentIntent),
});

export type CancelAppointmentDto = z.infer<typeof cancelAppointmentSchema>;
