import { z } from 'zod';

export const addSwipePhotosDtoSchema = z.object({
   score: z.number(),
   sessionDurationInSeconds: z.number(),
   totalImagesSwiped: z.number(),
   wrongImagesSwiped: z.number(),
});

export type AddSwipePhotosDto = z.infer<typeof addSwipePhotosDtoSchema>;
