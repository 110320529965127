import { extendApi } from '@anatine/zod-openapi';
import { z } from 'zod';

export const canBuyFollowOnsResponseDtoSchema = z.object({
   canBuy: z.boolean(),
   message: extendApi(z.string().optional(), {
      description: 'Reason why cannot buy, not present when canBuy is true',
   }),
});

export type CanBuyFollowOnsResponseDto = z.infer<
   typeof canBuyFollowOnsResponseDtoSchema
>;
