import { z } from 'zod';

export const patientCustomerVaultInfoResponseSchema = z.object({
   creditCard: z
      .object({
         type: z.string(),
         number: z.string(),
         expirationDate: z.string(),
      })
      .optional(),

   firstName: z.string().optional(),
   lastName: z.string().optional(),
   address1: z.string().optional(),
   address2: z.string().optional(),
   city: z.string().optional(),
   state: z.string().optional(),
   zip: z.string().optional(),
});

export type PatientCustomerVaultInfoResponse = z.infer<
   typeof patientCustomerVaultInfoResponseSchema
> | null;
