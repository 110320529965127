export enum MoodTrackingEnum {
   ExtremelyBad = 1,
   VeryBad,
   Bad,
   NeitherBad,
   Good,
   VeryGood,
   ExtremelyGood,
}

export enum MoodTrackingRangeEnum {
   Day = 'Day',
   Week = 'Week',
   Month = 'Month',
}
