import { z } from 'zod';

export enum WelkinPrograms {
   Intake = 'prog-intake',
   Extended = 'prog-medical',
   Foundation = 'prog-medical-8',
   FoundationAndTherapy = 'prog-therapy-model-new',
   FreeRange = 'prog-free-range',
   FollowOn = 'prog-follow-on',
   TwoDoses = 'prog-two-doses',
   Therapy = 'prog-therapy',
   GeneralPsychiatry = 'prog-general-psychiatry',
}

export type WelkinProgramPhases =
   | SharedProgramPhases
   | IntakeProgramPhases
   | ExtendedProgramPhases
   | FoundationProgramPhases
   | FreeRangeProgramPhases
   | FollowOnProgramPhases
   | TwoDosesProgramPhases
   | TherapyProgramPhases
   | GeneralPsychiatryProgramPhases;

export enum GeneralPsychiatryProgramPhases {
   Start = 'start',

   // Here we switch to the Extended layout
   ExtendedLayoutUnlocked = 'extended-layout-unlocked',

   MedicalClearanceWaiting = 'medical-clearance-waiting',

   // Generic
   PatientMissedAppointment = 'patient-missed-appointment',
   PatientDiscontinuedPrematurely = 'patient-discontinued-prematurely',
   PaymentFailed = 'payment-failed',
   PlanPaused = 'plan-paused',
   UnderReview = 'medical-review-in-progress',
   Finish = 'finish',
}

export enum TwoDosesProgramPhases {
   Start = 'start',
   ScheduleCapstoneFinished = 'schedule-capstone-finished',
   CapstoneFinished = 'capstone-finished',
   PatientMissedAppointment = 'patient-missed-appointment',
   PatientDiscontinuedPrematurely = 'patient-discontinued-prematurely',
   PaymentFailed = 'payment-failed',
   PlanPaused = 'plan-paused',
   UnderReview = 'medical-review-in-progress',
   Finish = 'finish',
}

export enum SharedProgramPhases {
   Start = 'start',
   PaymentFailed = 'payment-failed',
   PatientMissedAppointment = 'patient-missed-appointment',
   MissedAppointmentPaymentCompleted = 'missed-appointment-payment-completed',
   PatientDiscontinuedPrematurely = 'patient-discontinued-prematurely',
   PlanPaused = 'plan-paused',
   UnderReview = 'medical-review-in-progress',
   MedicalClearanceWaiting = 'medical-clearance-waiting',
   Finish = 'finish',
}

export enum IntakeProgramPhases {
   Start = 'start',
   StartScreener = 'start-screener',
   ScreenerInProgress = 'screener-in-progress',
   FinishScreener = 'finish-screener',
   ScreenerRejected = 'screener-rejected',
   ScreenerAccepted = 'screener-accepted',
   AccountCreated = 'account-created',
   PlanSelected = 'plan-selected',
   PaymentStarted = 'payment-started',
   // Triggered when first payment fails
   PaymentRejected = 'payment-rejected',
   PaymentSuccessful = 'payment-successful',

   HomeUnlocked = 'homepage-unlocked',
   // This phase is used for insurance purchase with chosen psychiatric clinician
   HomeUnlockedPsychiatricClinician = 'homepage-unlocked-psychiatric-clinician',
   // This phase is used for insurance purchase with chosen psychotherapist
   HomeUnlockedPsychotherapist = 'homepage-unlocked-psychotherapist',

   MedicalConsultOnly = 'medical-consult-only',
   MedicalIntakeStarted = 'medical-intake-started',
   MedicalIntakeInProgress = 'medical-intake-in-progress',
   MedicalIntakeFinished = 'medical-intake-finished',
   MedicalIntakeRejected = 'medical-intake-rejected',
   MedicalIntakeAccepted = 'medical-intake-accepted',
   UploadIdStarted = 'upload-id-started',
   UploadIdFinished = 'upload-id-finished',
   InformedConsentFormStarted = 'informed-consent-form-started',
   InformedConsentFormFinished = 'informed-consent-form-finished',
   DailyMoodTrackingFinished = 'daily-mood-tracking-finished',
   SetProgramGoalsFinished = 'set-program-goals-finished',
   CheckEligibility = 'check-eligibility',
   MedicalConsultSchedule = 'medical-consult-schedule',
   MedicalConsultStarted = 'medical-consult-started',
   MedicalConsultFinished = 'medical-consult-finished',
   MedicalConsultReject = 'medical-consult-reject',
   MedicalConsultAccept = 'medical-consult-accept',

   // Here we switch to the Extended Layout (for insurance patients that purchased non-ketamine plan)
   ExtendedLayoutUnlocked = 'extended-layout-unlocked',

   MedicalClearanceWaiting = 'medical-clearance-waiting',

   MissedAppointmentPaymentCompleted = 'missed-appointment-payment-completed',
   CarePlanConfirmed = 'care-plan-confirmed',
   PrescriptionSent = 'prescription-sent',
   PatientMissedAppointment = 'patient-missed-appointment',
   PatientDiscontinuedPrematurely = 'patient-discontinued-prematurely',
   PaymentFailed = 'payment-failed',
   PlanPaused = 'plan-paused',
   UnderReview = 'medical-review-in-progress',
   Finish = 'finish',
}

export enum TherapyProgramPhases {
   Start = 'start',

   MeetYourTherapistRejected = 'meet-your-therapist-rejected',

   // Here we switch to the Extended Emdr Layout
   ExtendedLayoutUnlocked = 'extended-layout-unlocked',

   // Generic
   PatientMissedAppointment = 'patient-missed-appointment',
   PatientDiscontinuedPrematurely = 'patient-discontinued-prematurely',
   PaymentFailed = 'payment-failed',
   PlanPaused = 'plan-paused',
   UnderReview = 'medical-review-in-progress',
   Finish = 'finish',
}

export enum ExtendedProgramPhases {
   Start = 'start',
   SetProgramGoals = 'set-program-goals',
   ConfirmRx1 = 'confirm-rx-1',

   ConfirmRx2 = 'confirm-rx-2',

   MissedAppointmentPaymentCompleted = 'missed-appointment-payment-completed',

   MedicalClearanceWaiting = 'medical-clearance-waiting',

   PatientMissedAppointment = 'patient-missed-appointment',
   PaymentFailed = 'payment-failed',

   Finish = 'finish',
}

export enum FoundationProgramPhases {
   Start = 'start',
   // SetProgramGoals = 'set-program-goals',

   MissedAppointmentPaymentCompleted = 'missed-appointment-payment-completed',

   MedicalClearanceWaiting = 'medical-clearance-waiting',

   PatientDiscontinuedPrematurely = 'patient-discontinued-prematurely',
   PatientMissedAppointment = 'patient-missed-appointment',
   PaymentFailed = 'payment-failed',

   Finish = 'finish',
}

export enum FreeRangeProgramPhases {
   Start = 'start',
   MissedAppointmentPaymentCompleted = 'missed-appointment-payment-completed',
   PaymentFailed = 'payment-failed',
   PatientMissedAppointment = 'patient-missed-appointment',
   PatientDiscontinuedPrematurely = 'patient-discontinued-prematurely',
   PlanPaused = 'plan-paused',
   Finish = 'finish',
}

export enum FollowOnProgramPhases {
   // If patient buys follow on with initial medical checkin
   // (after 32 days from capstone appointment)
   Start = 'start',
   ScheduleMedicalCheckinFinished = 'schedule-medical-checkin-finished',
   MedicalCheckinFinished = 'medical-checkin-finished',

   StartWithoutMedicalCheckin = 'start-without-medical-checkin',
   ScheduleCapstoneFinished = 'schedule-capstone-finished',
   CapstoneFinished = 'capstone-finished',

   PatientDiscontinuedPrematurely = 'patient-discontinued-prematurely',
   PatientMissedAppointment = 'patient-missed-appointment',
   MissedAppointmentPaymentCompleted = 'missed-appointment-payment-completed',
   PaymentFailed = 'payment-failed',
   PlanPaused = 'plan-paused',

   Finish = 'finish',
}

export const WelkinProgramPhasesEnum = z.union([
   z.nativeEnum(SharedProgramPhases),
   z.nativeEnum(IntakeProgramPhases),
   z.nativeEnum(ExtendedProgramPhases),
   z.nativeEnum(FoundationProgramPhases),
   z.nativeEnum(FreeRangeProgramPhases),
   z.nativeEnum(FollowOnProgramPhases),
   z.nativeEnum(TwoDosesProgramPhases),
   z.nativeEnum(TherapyProgramPhases),
   z.nativeEnum(GeneralPsychiatryProgramPhases),
]);

export const updatePatientPhaseDtoSchema = z.object({
   phaseName: WelkinProgramPhasesEnum,
});

export type UpdatePatientPhaseDto = z.infer<typeof updatePatientPhaseDtoSchema>;

export interface SitterInfo {
   name: string;
   email: string;
   phoneNumber: string;
   relationship: string;
   verified: boolean;
   sdsOneVerified: boolean;
   sdsTwoVerified: boolean;
   isEmergencyContact: boolean;
}

export interface EmergencyContactInfo {
   name: string;
   email: string;
   phoneNumber: string;
   relationship: string;
}

export const updateSitterDtoSchema = z.object({
   name: z.string().min(1, 'Sitter name is required.'),
   phone: z.string().min(1, 'Sitter phone is required.'), //transform phone ?
   email: z.string().email(),
   relationship: z.string().min(1, 'Sitter relationship is required.'),
   isEc: z.coerce.boolean(),
   sitterVerified: z.boolean().optional(),
   sdsOneVerified: z.boolean().optional(),
   sdsTwoVerified: z.boolean().optional(),
});

export type UpdateSitterDto = z.infer<typeof updateSitterDtoSchema>;

export const updateEmergencyContactDtoSchema = z.object({
   name: z.string(),
   phone: z.string(),
   email: z.string().email(),
   relationship: z.string(),
});

export type UpdateEmergencyContactDto = z.infer<
   typeof updateEmergencyContactDtoSchema
>;
