import { z } from 'zod';
import { slugField } from '../common';

export const createStateSchema = z.object({
   name: z.string().min(1, { message: 'Name is required' }),
   slug: slugField,
   shortName: z.string().min(1, { message: 'Abbreviation is required' }),
   active: z.boolean().optional(),
   providerNpi: z.string().optional(),
});

export type CreateStateDto = z.infer<typeof createStateSchema>;
