import { type z } from 'zod';
import { uuidSchema } from '../common/zod.fields';
import { createPaymentSchema } from '../payments';

export const cancelAppointmentPaymentSchema = createPaymentSchema
   .omit({
      cartId: true,
      paymentMethod: true,
   })
   .partial({
      billingInfo: true,
      shippingInfo: true,
   })
   .extend({
      missedAppointmentCdtId: uuidSchema,
   });

export type CancelAppointmentPaymentDto = z.infer<
   typeof cancelAppointmentPaymentSchema
>;
