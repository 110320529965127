export enum WhatBringsYouHere {
   Anxiety = 'anxiety',
   Depression = 'depression',
   EatingDisorder = 'eating_disorder',
   GenderDysphoria = 'gender_dysphoria',
   PersonalityDisorder = 'personality_disorder',
   PtsdAndTrauma = 'ptsd_and_trauma',
   SleepIssues = 'sleep_issues',
   Other = 'other',
}

export enum InnerwellProductOption {
   Therapy = 'therapy',
   Psychiatry = 'psychiatry',
   PsichiatryAndTherapy = 'psychiatry_therapy',
   KetamineAssistedPsychotherapy = 'ketamine_assisted_psychotherapy',
   KetamineTreatment = 'ketamine_treatment',
   StandalonePsychedelicIntegration = 'standalone_psychedelic_integration',
   Emdr = 'emdr',
}

export type CdtInnerwellData = {
   'cdtf-inches'?: number;
   'cdtf-feet'?: number;
   'cdtf-bmi'?: number;
   'cdtf-weight'?: number;
   'cdtf-gender'?: 'male' | 'female' | 'non_binary' | 'other';
   'cdtf-race'?: string;
   'cdtf-occupation'?: string;
   'cdtf-sitter_is_emergency_contact'?: boolean;
   'cdtf-what_brings_you_to_innerwell'?: WhatBringsYouHere;
   'cdtf-preferred-name'?: string;
   'cdtf-user-dob_year'?: number;
   'cdtf-ketamine-main_goals_with_our_program'?: string;
};
