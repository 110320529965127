import { z } from 'zod';
import { intExtendedField } from '../common/zod.fields';

export const ruleBasedBoundsParamsSchema = z.object({
   //    welkinEncounterTemplate: z.nativeEnum(WelkinEncounterTemplateNames),
   serviceId: intExtendedField,
   excludedEncounterIds: z.array(z.string().uuid()).optional(),
});

export type RuleBasedBoundsParamsDto = z.infer<
   typeof ruleBasedBoundsParamsSchema
>;
