import _ from 'lodash';
import { type NmiTransactionCreatedResponseDto } from '../../nmi/nmi-transaction-created-response.dto';
import { type AxiosResponse } from 'axios';
import { BaseError, type BaseErrorResponse } from '../../errors';

export type NmiErrorResponse = BaseErrorResponse & {
   __nmiError: true;
};

export class NmiError extends BaseError {
   __nmiError = true;

   constructor(
      public response: NmiTransactionCreatedResponseDto,
      public rawResponse: AxiosResponse<string>,
   ) {
      super(`NMI Transaction error: ${response.responsetext}`);
   }

   toJSON(): NmiErrorResponse {
      return {
         __nmiError: true,
         __baseError: true,
         message: this.message,
         type: this.type,
      };
   }
}

export function isNmiError(error: unknown): error is NmiError {
   return _.isObject(error) && (error as NmiError).__nmiError;
}
