import { z } from 'zod';
import { stateField } from '../common';

export const patchPatientDtoSchema = z.object({
   timezone: z.string().optional(),
   addressLine1: z.string().optional(),
   addressLine2: z.string().optional(),
   city: z.string().optional(),
   state: stateField.optional(),
   zip: z.string().optional(),
   email: z.string().email().optional(),
});

export type PatchPatientDto = z.infer<typeof patchPatientDtoSchema>;
