import { TherapyPlan } from '../therapy-plans';
import { z } from 'zod';

export const updatePatientSelectedPlanDtoSchema = z.object({
   plan: z.nativeEnum(TherapyPlan),
});

export type UpdatePatientSelectedPlanDto = z.infer<
   typeof updatePatientSelectedPlanDtoSchema
>;
