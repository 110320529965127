export enum AdminAppRoles {
   Admin = 'Admin',
   Clinician = 'Clinician',
}

export type JwtPayloadAdminObject = {
   sub: string;
   email_verified: boolean;
   iss: string;
   phone_number_verified: boolean;
   'cognito:groups': AdminAppRoles[];
   'cognito:username': string;
   'custom:welkinUserId'?: string;
   given_name: string;
   origin_jti: string;
   aud: string;
   event_id: string;
   token_use: string;
   auth_time: number;
   phone_number: string;
   exp: number;
   iat: number;
   jti: string;
   email: string;
};
