import {
   type MagentoPriceLevel,
   type MagentoProductTypeEnum,
} from '../magento';
import { type TherapyPlan } from '../therapy-plans';
import { type WelkinEncounterTemplateNames } from '../welkin';

export interface InnerwellProductDescriptionItem {
   heading: string;
   text: string;
}

export interface InnerwellProductChecklistItem {
   heading: string;
   subListItems?: string[];
}

export interface AdminMagentoProduct {
   id: number;
   sku: string;
   name: string;

   price: number;
   specialPrice: number | null;

   typeId: MagentoProductTypeEnum;
}

export interface AdminMagentoProductResponseDto {
   products: AdminMagentoProduct[];
   totalCount: number;
}

export type InnerwellEncounterCount = Record<string, number>;

export enum PaymentCode {
   OnceInFull = 'in-full', // When paid in full right away
   Weekly = 'weekly',
   Monthly = 'monthly',
   TwoMonths = 'monthly_2',
   FourMonths = 'monthly_4',
   Infinite = 'infinite',
   Free = 'free', // for $0 carts, provided by Magento directly

   // Used only if there's no CDT when sending CIO event (shouldn't happen)
   Unknown = 'unknown',
}

export enum PaymentFrequency {
   Weekly = 'weekly',
   Monthly = 'monthly',
}

export enum PaymentOption {
   InFull = 'in-full',
   Installments = 'installments',
}

export enum TierPriceType {
   Percentage = 'percentage',
   Fixed = 'fixed',
}

export interface InnerwellLegacyProductTypeVariant {
   name: string;
   durationName: string;
   totalPrice: number;
   amount: number;
   frequency: PaymentCode;
   code: PaymentCode;
   // discount amount per payment, total discount amount is then numberOfPayments * discountAmount
   discountAmount?: number;
   additionalCartDiscountAmount?: number;
   numberOfPayments: number;
}

type TierPrice = {
   qty: number;
   price: number;
   type: TierPriceType;
   discount: number;
};

export interface InnerwellProductVariant {
   id: number;
   name: string;
   typeId: string;
   title: string;
   subtitle: string;
   sku: string;
   price?: number;
   treatmentPrice: number | null;
   totalPrice?: number; // TO-DO -> remove, used only for legacy support in nmi-webhook listener
   specialPrice?: number | null;
   description: string;
   slug: string;
   therapyPlan: TherapyPlan | null;
   descriptionItems?: InnerwellProductDescriptionItem[];
   checklistItems?: InnerwellProductChecklistItem[];
   encountersCount: InnerwellEncounterCount;
   tierPrices?: TierPrice[];
   duration?: number | null;
   welkinEncounterId?: WelkinEncounterTemplateNames | null;
   categoryIds?: string[] | null;
   position?: number;
   positionsByCategory: Record<string, number>;
   priceLevel?: MagentoPriceLevel | null;
   numberOfDoses?: number | null;
   upsellProductSkus: string[];
   allowedPaymentMethods: PaymentCode[];
   // state abbreviation: CA, NY, etc
   availableInStates: string[] | undefined;
   discountCategories: string[];

   // Used for products that are not in Magento, but visible in frontend (Insurance flow for example)
   isMagentoProduct: boolean;
}

export interface InnerwellProduct {
   id: string;
   sku: string;
   name: string;
   position: number;

   variants: InnerwellProductVariant[];
}

export type ProductPlansResponseDto = {
   categoryId: number;
   products: InnerwellProduct[];
};

export type LegacyProduct = {
   id: string;
   isBaseProduct: boolean;
   index: number;
   sku: string;
   name: string;
   slug: string;
   therapyPlan: TherapyPlan;
   amount: number;
   totalPrice: number;
   frequency: string;
   numberOfPayments: number;
   descriptionItems?: InnerwellProductDescriptionItem[];
   checklistItems?: InnerwellProductChecklistItem[];

   variants: Record<PaymentOption, InnerwellLegacyProductTypeVariant>;
};
