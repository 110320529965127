import {
   type NmiAction,
   type NmiBillingAddress,
   type NmiCard,
   type NmiCondition,
   NmiEventType,
   type NmiMerchantDefinedFields,
   type NmiShippingAddress,
   type NmiTransactionChargeType,
   type NmiTransactionType,
   type NmiTransactParams,
   type NmiWebhookEvent,
} from '../nmi.types';

export interface TransactionData {
   transaction_id: string;
   transaction_type: NmiTransactionType; //NmiSource; // expecting cc
   condition: NmiCondition;
   processor_id: string;
   ponumber?: string;
   order_description?: string;
   order_id?: string;

   customerid?: string;
   customertaxid?: string;
   website?: string;
   shipping?: string; // probably price, format 20.00
   currency: string;
   tax: string;
   surcharge: string;
   cash_discount: string;
   tip: string;
   requested_amount: string; // format 20.00
   shipping_carrier?: string;
   tracking_number?: string;
   shipping_date?: string;
   partial_payment_id?: string;
   partial_payment_balance?: string;
   platform_id: string;
   authorization_code: string;
   social_security_number: string;
   drivers_license_number: string;
   drivers_license_state: string;
   drivers_license_dob: string;
   duty_amount: string;
   discount_amount: string;
   national_tax_amount: string;
   summary_commodity_code: string;
   alternate_tax_amount: string;
   vat_tax_amount: string;
   vat_tax_rate: string;
   billing_address: NmiBillingAddress;
   shipping_address: NmiShippingAddress;
   card: NmiCard;
   action: NmiAction;
   merchant_defined_fields?: NmiMerchantDefinedFields;
}

export type TransactionRequestDto = NmiWebhookEvent<TransactionData>;

export type FailedTransaction = {
   event_body: {
      transaction_id: string;
      order_id?: string;
      condition: NmiCondition;
      action: {
         amount: string;
      };
      currency: string;
      transaction_type: NmiTransactionChargeType;
   };
   params: Omit<NmiTransactParams, 'security_key'> | null;
};

export function isTransactionRequest(
   value: NmiWebhookEvent<unknown>,
): value is TransactionRequestDto {
   return [
      NmiEventType.TransactionSaleSuccess,
      NmiEventType.TransactionSaleFailure,
      NmiEventType.TransactionRefundSuccess,
      NmiEventType.TransactionVoidSuccess,

      NmiEventType.TransactionCaptureSuccess,
      NmiEventType.TransactionCaptureFailure,

      NmiEventType.TransactionAuthSuccess,
      NmiEventType.TransactionAuthFailure,

      // These are also considered valid transaction after the change to $0 carts
      // with some insurance products
      NmiEventType.TransactionValidateSuccess,
      NmiEventType.TransactionValidateFailure,
   ].includes(value.event_type);
}
