import _ from 'lodash';
import { ErrorTypes } from './error-types';

export interface ValidationError {
   name: ErrorTypes.ValidationError;
   // error: string;
   statusCode: number;
   // message: ValidationErrorItem[];
   message: string;
}

export function isValidationError(data: unknown): data is ValidationError {
   return (
      _.isObject(data) &&
      (data as ValidationError).name === ErrorTypes.ValidationError
   );
}
