import { z } from 'zod';
import { WelkinEncounterTemplateNames } from '../welkin';
import { booleanExtendedField } from '../common/zod.fields';

export const availablePrescribingCliniciansParamsSchema = z.object({
   // date and time range to be used when searching clinicians first available slot
   from: z.string(),
   to: z.string(),
   appointmentTemplate: z.nativeEnum(WelkinEncounterTemplateNames),
   forInsurance: booleanExtendedField.optional(),
});

export type AvailablePrescribingCliniciansParamsDto = z.infer<
   typeof availablePrescribingCliniciansParamsSchema
>;
