/* eslint-disable @typescript-eslint/naming-convention */
export enum CdtInnerwellScoring {
   'Not at all' = 0,
   'More than half the days',
   'Nearly every day',
   'Several days',
}

export interface CdtInnerwellPhq9v3 {
   'cdtf-depression-how_often_little_interest_in_doing_things': CdtInnerwellScoring;
   'cdtf-depression-how_often_felt_depressed': CdtInnerwellScoring;
   'cdtf-depression-had_trouble_falling_asleep': CdtInnerwellScoring;
   'cdtf-depression-felt_tired_or_lacking_energy': CdtInnerwellScoring;
   'cdtf-depression-had_poor_appetite_or_overeaten': CdtInnerwellScoring;
   'cdtf-depression-felt_bad_about_yourself': CdtInnerwellScoring;
   'cdtf-depression-had_trouble_concentrating': CdtInnerwellScoring;
   'cdtf-depression-been_moving_or_speaking_slowly': CdtInnerwellScoring;
   'cdtf-depression-had_thoughts_of_hurting_yourself': CdtInnerwellScoring;
   'cdtf-depression-period_when_you_rarely_felt_depressed': CdtInnerwellScoring;
   'cdtf-final_phq9_score': number;
   'cdtf-final-phq9-score': number;
   'cdtf-date': string;
}
