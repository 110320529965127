import { PaymentCode } from '../products';
import { z } from 'zod';
import { billingSchema } from './create-payment.schemas';
import { cartItemDtoSchema } from '../cart';

export type BillingInfoDto = z.infer<typeof billingSchema>;

export const insuranceInfoSchema = z.object({
   payerId: z.string().min(4).or(z.null()),
   payerName: z.string().or(z.null()),
   planName: z.string().or(z.null()),
   memberId: z.string().or(z.null()),
   databaseEntityId: z.string().uuid(),
   needsManualCheck: z.boolean().optional(),
   estimatedCoPay: z.number().or(z.null()),
   estimatedCoInsurance: z.number().or(z.null()),
   insuranceExpirationDate: z.string().optional(),
   isPrimarySubscriber: z.boolean().or(z.null()),
});

export type InsuranceInfoType = z.infer<typeof insuranceInfoSchema>;

export const creditCardSchema = z.object({
   number: z.string().min(10),
   expirationDate: z.string().length(4),
   cvv: z.string().min(3).max(4),
});

export type CreditCardDto = z.infer<typeof creditCardSchema>;

const paymentInfoSchema = z.discriminatedUnion('type', [
   z.object({
      type: z.literal('token'),
      paymentToken: z.string().optional(),
   }),
   z.object({
      type: z.literal('billingId'),
      billingId: z.string(),
   }),
   z.object({
      type: z.literal('card'),
      creditCard: creditCardSchema,
   }),
   z.object({
      type: z.literal('customerVault'),
   }),
]);

export type PaymentInfo = z.infer<typeof paymentInfoSchema>;

export const createPaymentSchema = z.object({
   paymentInfo: paymentInfoSchema,

   cartId: z.string().nonempty('Cart ID is required.'),
   paymentMethod: z.nativeEnum(PaymentCode),

   // this will be used for authentication, paymentToken is not required in that case
   noCustomerVault: z.boolean().optional(),
   // save to Customer Vault if true
   saveCreditCard: z.boolean().optional(),

   shippingInfo: billingSchema,
   billingInfo: billingSchema,

   selectedTherapistId: z.string().uuid().optional(),

   insuranceInfo: insuranceInfoSchema.optional(),
});

export type CreatePaymentDto = z.infer<typeof createPaymentSchema>;

export const cartSchema = z.object({
   discountCode: z.string().optional(),
   items: z.array(cartItemDtoSchema),
});

export type CartDto = z.infer<typeof cartSchema>;

export const createPaymentDevSchema = createPaymentSchema
   .omit({
      cartId: true,
   })
   .extend({
      // Cart to be created on the backend
      cart: cartSchema,
   });

export type CreatePaymentDevDto = z.infer<typeof createPaymentDevSchema>;

const withPatientSchemaFields = {
   formsortUUID: z.string().uuid().optional(),
   timezone: z.string().nonempty(),
   patientId: z.string().uuid().optional(),
};

export const createPaymentWithPatientSchema = createPaymentSchema
   .omit({
      // Saving by default unless noCustomerVault is true
      saveCreditCard: true,
   })
   .extend(withPatientSchemaFields);

export type CreatePaymentWithPatientDto = z.infer<
   typeof createPaymentWithPatientSchema
>;

export const createPaymentWithPatientDevSchema = createPaymentDevSchema.extend(
   withPatientSchemaFields,
);

export type CreatePaymentWithPatientDevDto = z.infer<
   typeof createPaymentWithPatientDevSchema
>;

export const validatePaymentWithPatientSchema = createPaymentWithPatientSchema
   .omit({
      cartId: true,
      paymentMethod: true,
      selectedTherapistId: true,
   })
   .extend({
      planType: z.enum(['clinician', 'psychotherapist']),
      // Not required in the new insurance flow where we don't check eligibility
      insuranceInfo: insuranceInfoSchema.optional(),
   });

export type ValidatePaymentWithPatientDto = z.infer<
   typeof validatePaymentWithPatientSchema
>;
