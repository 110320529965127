import { z } from 'zod';
import { dateField, intExtendedField } from '../common/zod.fields';

export const createPrescriptionSchema = z.object({
   patientId: z.string().uuid(),
   dfMedicationsCdtId: z.string().uuid(),
   //    pharmacyName: z.string(),
   welkinExternalId: z.string().nullable(),

   clinicianId: z.string().uuid(),
   medicationId: z.string().uuid(),

   quantity: intExtendedField,
   quantityUnit: z.string(),
   patientNotes: z.string(),

   eRxReferenceId: z.string().nullable(),

   startDate: dateField,
   stopDate: dateField.optional(),
   lastWrittenDate: dateField,
});

export type CreatePrescriptionDto = z.infer<typeof createPrescriptionSchema>;
