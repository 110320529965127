/* eslint-disable @typescript-eslint/naming-convention */
import { z } from 'zod';

export enum ResendCodeType {
   'email' = 'email',
   'phone_number' = 'phone_number',
}

export const resendCodeSchema = z.object({
   type: z.nativeEnum(ResendCodeType),
   accessToken: z.string(),
});

export type ResendCodeDto = z.infer<typeof resendCodeSchema>;
