import { type WelkinProgramPhases, type WelkinPrograms } from '../patients';

interface WelkinProgramCurrentPhase {
   timestamp: string;
   createdBy: string;
   createdByName: string;
   name: WelkinProgramPhases;
   title: string;
   description: string;
   currentVersion: string;
   deleted: false;
}

type WelkinProgramPathHistoryItem = WelkinProgramCurrentPhase;

export enum WelkinPatientProgramStatus {
   NotStarted = 'NOT_STARTED',
   InProgress = 'IN_PROGRESS',
   Interrupted = 'INTERRUPTED',
   Completed = 'COMPLETED',
}

export interface WelkinPatientProgramResponseDto {
   id: string;
   createdByName: string;
   createdAt: string;
   updatedByName: string;
   updatedAt: string;
   programName: WelkinPrograms;
   programTitle: string;
   programDescription: string;
   assigned: boolean;
   patientId: string;
   currentPhase: WelkinProgramCurrentPhase;
   status: WelkinPatientProgramStatus;
   compatibleWithCurrentVersion: boolean;
   pathHistory: WelkinProgramPathHistoryItem[];
}
