import { z } from 'zod';

export const sessionInfo = z.object({
   sub: z.string(),
   email: z.string(),
   patientId: z.string(),
   formsortUUID: z.string(),
   jwtExpires: z.number(),
});

export type SessionInfoDto = z.infer<typeof sessionInfo>;
