// Form types are passed from the Formsort webhook.
// Example:

import { type StateAbbrValue } from '@innerwell/us-states';
import {
   type InnerwellProductOption,
   WelkinCdtDbAction,
   type WhatBringsYouHere,
} from '../welkin';
import { z } from 'zod';
import { type FormsortFormData } from '@innerwell/prisma-app-generated';

// /api/formsort/hook/medical-intake-profile where medical-intake-profile is form type
export enum FormsortFormTypes {
   TriageQuestion = 'triage-question',
   InitialScreening = 'initial_screening',
   InitialScreeningNonKetamine = 'new-flow',
   MedicalIntakeProfile = 'medical-intake-profile',
   MedicalIntakeMedicalHistory = 'medical-intake-history',
   MedicalIntakePsychiatricHistory = 'medical-intake-psychiatric-history',
   MedicalIntakeMentalHealth = 'medical-intake-mental-health',
   MedicalIntakePsychedelicExperiences = 'medical-intake-psychedelic-experiences',
   PsychiatryAndTherapyIntake = 'intake',
   ProgramGoals = 'program-goals',
   ADS = 'autonomous-dosing-session',
   ADSIntentions = 'ads-intentions',
   WeeklyPause = 'weekly-pause',
   UserConsent = 'user-consent',
   PatientSurvey = 'innerwell-patient-survey',
   EntheaIntake = 'enthea-intake',
}

export const formsortFormTypeSchema = z.custom<FormsortFormTypes>((val) => {
   return typeof val === 'string';
});

export const INITIAL_SCREENING_FORM_TYPES = [
   FormsortFormTypes.InitialScreening,
   FormsortFormTypes.InitialScreeningNonKetamine,
];

export type FormsortFormAnswerTypes =
   | SignupScreeningAnswers
   | InitialScreeningAnswers
   | MedicalIntakeProfileAnswers
   | MedicalIntakeMedicalHistoryAnswers
   | MedicalIntakePsychiatricHistoryAnswers
   | MedicalIntakeMentalHealthAnswers
   | MedicalIntakePsychedelicExperiencesAnswers
   | ProgramGoalsAnswers;

export interface MedicalIntakeProfileAnswers {
   // Cognito username
   cognitoSub: string;

   // DoB
   user_has_existing_dob?: boolean;
   'user-dob_month'?: number;
   'user-dob_day'?: number;
   'user-dob_year'?: number;

   feet: number;
   inches: number;
   weight: number;
   // Calculated variable for feet/inches/weight and height
   // BMI = [Weight (lbs) / Height (inches)²] x 703
   bmi: number;
   height: string;

   gender: string;
   preferred_pronouns: string;
   preferred_pronouns_other?: string;
   race: string;

   occupation: string;

   // Sitter info
   sitter_is_emergency_contact: boolean;
   'sitter-name': string;
   'sitter-relationship': string;
   'sitter-phone': string;
   'sitter-email': string;
   'ec-name'?: string;
   'ec-relationship'?: string;
   'ec-phone'?: string;
   'ec-email'?: string;

   // Address
   address_line_1: string;
   address_line_2: string;
   city: string;
   state: string;
   zip_code: string;
}
interface Prescription {
   name: string;
   dosage: string;
   frequency: string;
}
interface Allergy {
   name: string;
}
interface Supplement {
   name: string;
   dosage: string;
   frequency: string;
}

interface Medication {
   name: string;
}

export interface MedicalIntakeMedicalHistoryAnswers {
   // Cognito username
   cognitoSub: string;

   // PCP
   'do-you-have-pcp': boolean;
   'pcp-name'?: string;
   'pcp-facility'?: string;
   'pcp-phone'?: string;

   'mp-date_of_your_last_physical_exam': string;
   'mp-prescription-medications': {
      prescriptions: Prescription[];
   };
   'mp-allergies': {
      prescriptions: Allergy[];
   };
   'mp-supplements': {
      prescriptions: Supplement[];
   };

   // Health issues
   'mp-heart_problems': string[];
   'mp-neurological_issues'?: string[];
   'mp-other_health_issues'?: string[];

   // Sleeping issues
   'mp-diagnosed_with_sleep_apnea'?: boolean;
   'mp-cpap_therapy_sleep_apnea'?: boolean;
}

export interface MedicalIntakePsychiatricHistoryAnswers {
   // Cognito username
   cognitoSub: string;

   // Disorders
   eating_disorders: string[];
   personality_disorders?: string[];
   psychiatric_conditions?: string[];

   // Multiline text explanations for 'other'
   other_eating_disorder_explanation?: string;
   other_personality_disorder_explanation?: string;
   other_psychiatric_condition_explanation?: string;
   hospitalized_for_psychiatric_care_explanation?: string;
   made_a_suicide_attempt_explanation?: string;

   // Rejected reasons
   currently_restricting_food_intake?: boolean;
   when_was_recent_manic_or_hypomanic_episode?: string;
   presently_experiencing_manic_or_hypomanic_symptoms?: boolean;

   previously_prescribed_psychiatric_medications_that_is_longer_taking?: boolean;
   'mp-previously_prescribed_psychiatric_meditations_that_is_longer_taking'?: {
      prescriptions: Medication[];
   };
   hospitalized_for_psychiatric_care?: boolean;
   made_a_suicide_attempt?: boolean;
   currently_working_with_a_mental_health_professional?: string[];

   // Contact
   may_we_contact_your_psychiatrist?: boolean;
   psychiatrist_name?: string;
   psychiatrist_phone_number?: string;
   psychiatrist_email?: string;
   may_we_contact_your_therapist?: boolean;
   therapist_name?: string;
   therapist_phone_number?: string;
   therapist_email?: string;
}
export interface MedicalIntakePsychedelicExperiencesAnswers {
   // Cognito username
   cognitoSub: string;

   had_a_previous_psychedelic_experience: boolean;
   which_statements_do_you_most_agree_with?: string;

   // Psychedelic experiences flow
   psychedelic_substances_experienced?: string[];
   other_psychedelic_substances_explanation?: string;

   had_designated_guide_or_sitter?: boolean;
   was_the_purpose_primarily_recreational?: boolean;
   had_therapeutic_goals_for_psychedelic_experience?: boolean;

   did_experience_provide_lasting_benefit_or_changes?: boolean;
   lasting_benefits_or_changes_explanation?: string;

   were_there_very_frightening_or_other_challenging_aspects_to_the_experience?: boolean;
   challenging_aspects_explanation?: string;

   had_a_bad_trip?: boolean;
   bad_trip_explanation?: string;
}

export interface MedicalIntakeMentalHealthAnswers {
   // Cognito username
   cognitoSub: string;

   // Depression questions - It is dependent on Initial Screening depression questions
   // so all fields are optional
   'depression-had_trouble_falling_asleep'?: string;
   'depression-felt_tired_or_lacking_energy'?: string;
   'depression-had_poor_appetite_or_overeaten'?: string;
   'depression-felt_bad_about_yourself'?: string;
   'depression-had_trouble_concentrating'?: string;
   'depression-been_moving_or_speaking_slowly'?: string;
   'depression-had_thoughts_of_hurting_yourself'?: string;
   'depression-period_when_you_rarely_felt_depressed'?: string;

   // Anxiety questions - It is dependent on Initial Screening depression questions
   // so all fields are optional
   'anxiety-worrying_too_much'?: string;
   'anxiety-had_trouble-relaxing'?: string;
   'anxiety-hard_to_sit_still'?: string;
   'anxiety-become_easily_annoyed_or_irritable'?: string;
   'anxiety-felt-afraid'?: string;

   // Follow up questions after depression & anxiety (if user had to answer them)
   how_satisfied_with_current_sleep_pattern: string;
   sleep_problem_interfere_with_current_daily_functioning: string;
   worried_about_current_sleep_problem: string;
   how_often_had_difficulty_falling_asleep: string;
   // These three questions will have a score
   // if >= 7 -> continue with the steps --- if < 7 end the form
   how_often_had_difficulty_staying_asleep: string;
   how_often_had_problems_waking_up_too_early: string;
   how_noticeable_others_think_your_sleep_problem_is_imparing_qof: string;
   sleeping_problems_score: number;

   // Follow up questions if score >= 7
   how_often_had_more_than_normal_drinks_in_a_day?: string;
   how_often_used_tobacco_products?: string;
   how_often_used_prescription_drugs_for_nonmedical_reasons?: string;
   how_often_used_illegal_drugs?: string;

   // Used substances in lifetime
   used_cannabis?: boolean;
   how_often_cannabis?: string;
   used_cocaine?: boolean;
   how_often_cocaine?: string;
   used_methamphetamine?: boolean;
   how_often_methamphetamine?: string;
   used_inhalants?: boolean;
   how_often_inhalants?: string;
   used_hallucinogens?: boolean;
   how_often_hallucinogens?: string;
   used_street_opioids?: boolean;
   how_often_street_opioids?: string;
   used_other_substances?: boolean;
   other_substances_explanation?: string;

   used_drugs_without_prescription?: boolean;
   how_often_used_nonprescribed_drugs_last_3_months?: string;

   // Final PHQ9/GAD7 (partially from Initial screening via variable initialPHQ9Score + answers)
   final_phq9_score: number;
   final_gad7_score: number;

   // Final ISI score (calculated only from this form)
   final_isi_score: number;
}

type UserStateWithNotify = {
   state: StateAbbrValue;
   isNotifySelected: boolean;
};

export interface SignupScreeningAnswers {
   // User
   'user-first_name': string;
   'user-dob_month': number;
   'user-dob_day': number;
   'user-dob_year': number;
   'user-email': string;
   'user-state-with-notify': UserStateWithNotify;
   // autocalculated value from user-state-with-notify
   'user-state': StateAbbrValue;
   'user-phone': string;

   // @NOTE: only used on first screener, that's why they're optional here
   // Calculated variables if user answered B,C,D
   hasDepression?: boolean;
   hasAnxiety?: boolean;
   what_brings_you_to_innerwell?: WhatBringsYouHere[];

   // @NOTE: I don't think there are used anymore
   // Calculated variable for initial PHQ9/GAD7 score
   initial_phq9_score?: number;
   initial_gad7_score?: number;

   'product-option': string;
   insuranceCatId?: number;
   purchasePlanId?: number;
}

export interface TriageScreeningAnswers {
   'product-option': InnerwellProductOption;
   what_brings_you_to_innerwell?: WhatBringsYouHere[];

   'user-email': string;
   'user-state-with-notify': UserStateWithNotify;
   // autocalculated value from user-state-with-notify
   'user-state': StateAbbrValue;
}

export interface InitialScreeningAnswers extends SignupScreeningAnswers {
   // Calculated birthday and age
   birthday: string;
   age: number;

   // Ketamine section
   'ketamine-have_you_received_treatment'?: boolean;
   'ketamine-how_did_you_receive_ketamine'?: string;
   'ketamine-did_it_help_you'?: boolean;
   'ketamine-main_goals_with_our_program'?: string[];
   'ketamine-medical_history'?: string;
}

export interface ProgramGoalsAnswers {
   areas_of_focus: string[];
   areas_of_focus_other?: string;

   areas_to_strengthen: string[];
   areas_to_strengthen_other?: string;

   feelings_to_understand: string[];
   feelings_to_understand_other?: string;

   behavior_patterns: string;
   reflect_happy_times: string;
   visualize_your_life_six_months: string;
   visualize_life_six_months_how: string;
}

export const descriptionJsonTypeSchema = z.object({
   name: z.string(),
   fieldName: z.string().optional(),
   handler: z.nativeEnum(WelkinCdtDbAction).optional(),
});

export type FormSortJsonDescriptionDto = z.infer<
   typeof descriptionJsonTypeSchema
>;

export const formsortDescriptionFromJson = (
   content: string,
): FormSortJsonDescriptionDto => {
   return z
      .custom<string>(() => {
         try {
            JSON.parse(content);
         } catch (error) {
            return false;
         }
         return true;
      }, 'invalid json') // write whatever error you want here
      .transform((stringContent) => JSON.parse(stringContent) as unknown)
      .pipe(descriptionJsonTypeSchema)
      .parse(content);
};

export type FormsortAnswersResponse<T = unknown> = Omit<
   FormsortFormData,
   'answers'
> & {
   answers: T;
};
