import { TherapyPlan } from '../../therapy-plans';

export enum WelkinSpecificTherapyPlanOptions {
   ReferOut = 'Refer Out',
}

export type CarePlanChoiceEnum = TherapyPlan | WelkinSpecificTherapyPlanOptions;

export const CarePlanChoicesEnum = {
   ...TherapyPlan,
   ...WelkinSpecificTherapyPlanOptions,
};

export type CdtCarePlanChoice = {
   'cdtf-care-plan-choice': CarePlanChoiceEnum;
};
