import { DateTime } from 'luxon';
import { z } from 'zod';
import { ClaimMdPatientRelationshipCode } from '@innerwell/api-claim-md-types';

export const personEligibilitySchema = z.object({
   firstName: z.string().min(1, 'First name is required.'),
   lastName: z.string().min(3, 'Last name is required.'),
   dob: z
      .string()
      .refine(
         (value) => {
            try {
               DateTime.fromISO(value, { zone: 'utc' });
               return true;
            } catch (error) {
               return false;
            }
         },
         {
            message: 'Date of birth is incorrect.',
         },
      )
      .transform((value) => {
         return DateTime.fromISO(value, { zone: 'utc' });
      })
      .optional(),
});

export const insuredEligibilitySchema = personEligibilitySchema.extend({
   memberId: z.string().optional(),
});

export const getEligibilitySchema = z.object({
   insured: insuredEligibilitySchema,
   serviceDate: z
      .string()
      .datetime({
         offset: false,
         // precision: 0,
      })
      .transform((value) => {
         return DateTime.fromISO(value, { zone: 'utc' });
      }),
   stateAbbr: z.string().min(2).max(2),
   payerId: z.string().min(4, 'Payer ID is required.'),
   serviceCodes: z.array(z.string().min(1)).optional(),
   relationshipCode: z.nativeEnum(ClaimMdPatientRelationshipCode).optional(),
   // only when ClaimMdPatientRelationshipCode.Dependent
   patient: personEligibilitySchema.optional(),
   formsortUUID: z.string().uuid().or(z.null()),
});

export type GetEligibilityDto = z.infer<typeof getEligibilitySchema>;

export const getEligibilityIntakeSchema = getEligibilitySchema.pick({
   insured: true,
   relationshipCode: true,
   payerId: true,
});

export type GetEligibilityIntakeDto = z.infer<
   typeof getEligibilityIntakeSchema
>;
