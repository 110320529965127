import { z } from 'zod';
import { intExtendedField } from '../common/zod.fields';

export interface LevelResultDto {
   date: string; // ISO date
   score: number | null;
}

export interface LevelsDto {
   levels: LevelResultDto[];
}

export const levelsParamsSchema = z.object({
   size: intExtendedField.refine((value) => value > 0, {
      message: 'Size must be greater than 0',
   }),
   page: intExtendedField
      .refine((value) => value >= 0, {
         message: 'Page must be greater than or equal to 0',
      })
      .optional(),
   sort: z.enum(['asc', 'desc']).optional(),
});

export type LevelsParamsDto = z.infer<typeof levelsParamsSchema>;
