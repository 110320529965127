import { z } from 'zod';
import {
   booleanExtendedField,
   dateTimeField,
   intExtendedField,
} from '../common';

export interface WelkinTimestamps {
   createdAt: string;
   updatedAt: string;
   createdBy: string;
   updatedBy: string;
}

export type WelkinResponseStandardFields = {
   external_guid?: string;
   created_at: string;
   source_type?: string;
   external_id?: string;
   created_by_name: string;
   created_by: string;
   updated_by_name: string;
   updated_at: string;
   updated_by: string;
   id: string;
   source_id?: string;
   source_name?: string;
};

export enum WelkinRole {
   SuperAdmin = 'super-admin',
   PrescribingClinician = 'r-prescribing-clinician',
   Therapist = 'r-therapists',
   EmdrTherapist = 'r-emdr',
}

export enum WelkinFormType {
   InitialScreening = 'asm-medical-intake-initial-screening',
   MedicalIntakeProfile = 'asm-medical-intake-profile',
   MedicalIntakeMedicalHistory = 'asm-medical-history',
   MedicalIntakePsychiatricHistory = 'asm-medical-intake-psychiatric-history',
   MedicalIntakeMentalHealth = 'asm-medical-intake-mental-health',
   MedicalIntakePsychedelicExperiences = 'asm-psychedelic-experiences',
   CarePlanConfirmation = 'asm-care-plan-confirmation',
   RoiAndMedicalClearance = 'asm-roi-and-or-medical-clearance',
   SafetyPlan = 'asm-safety-plan-', // Yes, there's a trailing dash in Welkin
}

export enum WelkinEventTypes {
   Encounter = 'ENCOUNTER',
   GroupTherapy = 'GROUP_THERAPY',
   Appointment = 'APPOINTMENT',
   Leave = 'LEAVE',
}

export enum WelkinEventModes {
   Video = 'VIDEO',
   Call = 'CALL',
   InPerson = 'IN-PERSON',
}

export enum EventParticipantRole {
   PSM = 'psm',
   Patient = 'patient',
}

export interface WelkinEncounterParticipant {
   id: string;
   participantId: string;
   participantRole: EventParticipantRole;
   attended: boolean;
   timezone: string;
   firstName: string;
   lastName: string;
   deleted: boolean;
   hasAccess: boolean;
   email: string;
   phone: string | null;
   gender: string | null;
   birthDate: string | null;
   enabled: boolean;
}

export enum WelkinCalendarEventStatus {
   Scheduled = 'SCHEDULED',
   Cancelled = 'CANCELLED',
   Completed = 'COMPLETED',
   Missed = 'MISSED',
}

export enum WelkinEncounterStatus {
   Planned = 'DRAFT',
   Completed = 'FINALIZED',
   InProgress = 'OPEN',
   Cancelled = 'CANCELLED',
}

export enum WelkinCdtEnum {
   CdtPatientDataRelationsIds = 'cdt-patient-data-relations-ids',
   CdtSitter = 'cdt-innerwell-sitter-info',
   CdtEmergencyContact = 'cdt-innerwell-emergency-contact',
   CdtShippingLog = 'cdt-shipping-log',
   CdtPatientChosenCarePlanV2 = 'cdt-patient-chosen-care-plan-v2',
   InnerwellCdt = 'cdt-innerwell-data',
   CdtSendWeeklyForm = 'cdt-send-weekly-form',
   CdtDiagnosis = 'diagnosis',
   CdtCarePlanChoice = 'cdt-care-plan-choice',
   CdtAdsCounter = 'cdt-ads-counter',
   CdtIcdt10Codes = 'cdt-innerwell-icd-10-codes-v2',
   CdtPatientPaymentInformation = 'cdt-patient-payment-information',
   CdtInnerwellPCL5 = 'cdt-innerwell-pcl5',
   CdtDosageConfirmation = 'cdt-innerwell-dosage-confirmation',
   CdtBloodPressure = 'cdt-innerwell-blood-pressure',
   CdtInnerwellMoodTracking = 'cdt-innerwell-mood-tracking',
   CdtPrescriptionMedications = 'cdt-innerwell-prescription-medications',
   CdtGad7 = 'cdt-innerwell-gad7-v2',
   CdtPhq9 = 'cdt-innerwell-phq9-v3',
   CdtEncounterBank = 'cdt-encounter-bank',
   CdtFollowOnPurchases = 'cdt-follow-on-purchases',
   CdtPatientDiscontinuedPrematurely = 'cdt-patient-discontinued-prematurely',
   CdtAdsIntentions = 'cdt-innerwell-ads-intentions',
   CdtContinuedCareRecommendation = 'cdt-continued-care-recommendation',
   CdtContactConsent = 'cdt-contact-consent',
   CdtRoiRequest = 'cdt-innerwell-roi-request',
   CdtDoses = 'cdt-doses',
   CdtInsuranceInfo = 'cdt-insurance-info',
   CdtKetamineEffectsQuestionnaire = 'cdt-ketamine-effects-questionnaire',
   CdtMedicalIntakeStatus = 'cdt-medical-intake-status',
   CdtMissedAppointments = 'cdt-missed-appointments',
   // Type: single
   // Used to store various patient params.
   // cdtf-should-check-insurance-eligibility: helps with choosing a proper phase in the intake after
   //    program goals are finished. If true, should check for eligibility.
   CdtPatientParams = 'cdt-patient-params',
   CdtFailedPayment = 'cdt-failed-payment',
   CdtRefund = 'cdt-refund',
   CdtPatientDischarged = 'cdt-patient-discharged',

   // data driven care
   CdtClinicianInputs = 'cdt-clinician-inputs',
   CdtClinicianInputsKetamineTracking = 'cdt-clinician-inputs-ketamine-tracking',

   // Games
   CdtBreathingExercises = 'cdt-game_breathing_exercises',
   CdtGameSwipePhotos = 'cdt-game_swipe_photos',
   // Dr. First
   CdtDrFirstMedications = 'df_medications',
   CdtDrFirstMedicationInstructions = 'df_medication_instructions',

   // Reserved, internal
   // eslint-disable-next-line @typescript-eslint/naming-convention
   _Encounter = '__encounter__',
   // eslint-disable-next-line @typescript-eslint/naming-convention
   _EncounterDisposition = '__encounter_disposition__',
}

export enum WelkinDocumentTypes {
   MedicalRecords = 'medical-records',
   MedicalClearance = 'dt-medical-clearance',
   DischargeLetterAndTreatmentSummary = 'dt-discharge-letter-and-or-treatment-summary',
   Roi = 'dt-roi',
   SafetyPlan = 'dt-safety-plan',
   Labs = 'dt-labs',
}

export enum WelkinCdtDbAction {
   CreateCdt,
   UpdateCdt,
}

// Returned by list of appointments
export interface WelkinMetaInfo {
   page: number;
   pageSize: number;
   totalElements: number;
   numberOfElements: number;
   firstPage: boolean;
   lastPage: boolean;
   totalPages: number;
}

export interface WelkinTerritory extends WelkinTimestamps {
   name: string;
   territories: string[];
   instanceName: string;
}

export enum WelkinSecurityType {
   Policy = 'SECURITY_POLICY',
   Role = 'SECURITY_ROLE',
}

export interface WelkinUserRole
   extends Pick<WelkinTimestamps, 'createdAt' | 'updatedAt'> {
   permissionName: WelkinRole;
   instanceName: string;
   type: WelkinSecurityType;
   primaryRole: string;
}

export interface WelkinPageableSort {
   sorted: boolean;
   unsorted: boolean;
   empty: boolean;
}

export interface WelkinPageable {
   sort: WelkinPageableSort;
   pageNumber: number;
   pageSize: number;
   offset: number;
   paged: boolean;
   unpaged: boolean;
}

export interface WelkinPageableResponseAdditionalFields {
   last: boolean;
   totalElements: number;
   totalPages: number;
   first: boolean;
   number: number;
   sort: WelkinPageableSort;
   numberOfElements: number;
   size: number;
   empty: boolean;
}

export type WelkinPageableResponse<T> = {
   content: T[];
   pageable: WelkinPageable;
} & WelkinPageableResponseAdditionalFields;

export type WelkinPageableResponseWithMetaInfo<T> = {
   data: T[];
   metaInfo: WelkinMetaInfo;
};

export interface WelkinCursor {
   start: string | null;
   end: string | null;
   hasNext: boolean;
   pageSize: number;
}

export type WelkinPageableResponseWithCursor<T> = {
   data: T[];
   cursor: WelkinCursor;
};

export enum WelkinEncounterTemplateNames {
   // Scheduling Event #1
   MedicalConsult = 'etmp-prescribing-clinician-intake',

   // Scheduling Event #2
   TherapistIntake = 'etmp-2-therapist-intake',
   TherapistPrepCall = 'etmp-3-therapist-prep-call',
   TherapistSDS1 = 'etmp-4-supervised-dosing-1',
   TherapistSDS2 = 'etmp-therapist-supervised-dosing-2',
   // Medical Model
   MedicalFollowUp2 = 'etmp-medical-follow-up-2',
   MedicalFollowUp3 = 'etmp-medical-follow-up-3',

   // Medical 8
   FreeTherapySession = 'etmp-free-therapy-session',

   // Shared
   MedicalFollowUp = 'etmp-medical-follow-up',

   // Scheduling Event #3
   IntegrationSession1 = 'etmp-7-therapist-integration-session-1',
   IntegrationSession2 = 'etmp-therapist-integration-session-2',
   IntegrationSession3 = 'etmp-therapist-integration-session-3',
   IntegrationSession4 = 'etmp-therapist-integration-session-4',
   CapstoneAppointment = 'etmp-final-medical-check-in',

   // ExtraMedicalCheckIn = 'etmp-extra-medical-check-in',
   Integration30MinALaCarte = 'etmp-integration---30-minutes--a-la-carte-',
   Integration50MinALaCarte = 'etmp-integration---50-minutes--a-la-carte-',
   MeetYourTherapistALaCarte = 'etmp-meet-your-therapist--a-la-carte-',
   Preparation30MinALaCarte = 'etmp-preparation-session---30-minutes--a-la-carte-',
   Preparation50MinALaCarte = 'etmp-preparation-session---50-minutes--a-la-carte-',
   SupervisedDosingALaCarte = 'etmp-supervised-dosing-session--a-la-carte-',

   // Therapy + Psychiatry
   TalkTherapy30Min = 'etmp-talk-therapy-30-minutes',
   TalkTherapy50Min = 'etmp-talk-therapy-50-minutes',
   TherapistIntake50Min = 'etmp-therapist-intake-50',
   EmdrIntake = 'etmp-emdr-intake',
   EmdrSession = 'etmp-emdr-50-min',
   PsychedelicIntegration30Min = 'etmp-pscyhedelic-integration-30-minutes',
   PsychedelicIntegration50Min = 'etmp-pscyhedelic-integration-50-minutes',
   MedicalConsultPsychiatry = 'etmp-medical-consult-psychiatry',
   MedicalCheckInPsychiatry = 'etmp-medical-checkin-psychiatry',

   // Additional check in added manually in Welkin
   MedicalCheckIn = 'medical-check-in',

   // Consult only (insurance)
   MeetYourTherapistInsurance = 'etmp-meet-your-therapist-insurance',
   IntegrationSessionInsurance = 'etmp-integration-session-insurance',
   PreparationSessionInsurance = 'etmp-preparation-session-insurance',
   EmdrSessionInsurance = 'etmp-emdr-session-insurance',
   EmdrIntakeInsurance = 'etmp-emdr-intake-insurance',
   TalkTherapyInsurance = 'etmp-talk-therapy-session-insurance',

   // For marketing website purposes
   MarketingAvailabilityMeeting = 'etmp-marketing-availability',
}

export enum WelkinAssessmentEnum {
   InitialEvaluation = 'asm-prescribing-clinician-intake-form',
   WeeklyMoodTracking = 'asm-weekly-mood-tracking',
   ShippingLog = 'asm-shipping-log',
   ChartReview = 'asm-chart-review',
   DischargeNote = 'asm-discharge-note',
}

export enum WelkinAssessmentStatus {
   New = 'NEW',
   Expired = 'EXPIRED',
   InProgress = 'IN_PROGRESS',
   Submitted = 'SUBMITTED',
   Completed = 'COMPLETED',
}

export interface FindCalendarEventsParams {
   from: Date;
   to: Date;
   participantIds?: string[];
   eventType?: WelkinEventTypes[];
   includeCancelled?: boolean;
   includeEncountersInfo?: boolean; // Should or not API include encounterInfo field into dto (the field is exists for events with ENCOUNTER event type) (default: false)
   excludeAssignedToEncounterEvents?: boolean; // If true, only events that are not assigned to encounter will return (default: false)
   sort?: string;
   viewerTimezone?: string;
   page?: number;
}

interface EventUpdateParticipants {
   participantId: string;
   participantRole: EventParticipantRole;
}

export interface CreateEventParams {
   eventTitle: string;
   eventDescription?: string;
   allDayEvent?: boolean;
   startDateTime: string;
   endDateTime: string;
   eventType?: WelkinEventTypes;
   eventStatus?: WelkinCalendarEventStatus;
   eventMode?: WelkinEventModes;
   eventColor?: string;
   timezone?: string;
   externalId?: string | null;

   hostId: string;
   participants: EventUpdateParticipants[];
}

export interface CreateEncounterParams {
   title: string;
   templateName?: WelkinEncounterTemplateNames;
   instruction?: string;
   currentScheduledAppointment: CreateEventParams;
}

export type PatchEncounterParams = Partial<
   Omit<CreateEncounterParams, 'currentScheduledAppointment'>
> & {
   currentScheduledAppointment?: Partial<CreateEventParams>;
   status?: WelkinEncounterStatus;
};

// Todo: this might just be CDT field
export interface WelkinAssessmentCdtField {
   name: string;
   type: string;
   formula: string | null;
   dictionary: null; // todo: type
   profileDataType: null; // todo
   editable: boolean;
   searchable: boolean;
   bulkEdit: boolean;
   phi: boolean;
   disable: boolean;
   injectedSource: boolean;
   displayType: null; // todo
   valueType: null; // todo
   meta: {
      label: string;
      required: boolean;
   };
}

export interface WelkinAssessmentCdtResponse {
   id: string;
   name: string;
   title: string | null;
   label: string;
   version: number;
   internal: boolean;
   readable: boolean;
   updateable: boolean;
   type: string;
   relation: string;
   fields: WelkinAssessmentCdtField[];
}

export enum AssessmentStatusEnum {
   InProgress = 'IN_PROGRESS',
   Completed = 'COMPLETED',
   New = 'NEW',
}

export const welkinListEncountersParamsSchema = z.object({
   statuses: z.array(z.nativeEnum(WelkinEncounterStatus)).optional(),
   page: intExtendedField.optional(),
   size: intExtendedField.optional(),
   sort: z.string().optional(),
   onlyWithCalendarEvent: booleanExtendedField.optional(),
   ids: z.array(z.string().uuid()).optional(),
   calendarEventFrom: dateTimeField.optional(),
   calendarEventTo: dateTimeField.optional(),
   sortByCalendar: z.enum(['ASC', 'DESC']).optional(),
   withCareTeam: booleanExtendedField.optional(),
   templateNames: z
      .array(z.nativeEnum(WelkinEncounterTemplateNames))
      .optional(),
});

export type WelkinListEncountersParamsDto = z.infer<
   typeof welkinListEncountersParamsSchema
>;

export const welkinEncounterTypeSchema = z.nativeEnum(
   WelkinEncounterTemplateNames,
);

export type WelkinExportParams = {
   start?: Date;
   pageSize?: number;
};
