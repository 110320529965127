import { z } from 'zod';
import { slugField } from '../common';

export const createInsuranceSchema = z.object({
   internalName: z.string().min(3),
   name: z.string().min(3),
   payerId: z.string().min(4),
   stateId: z.string().uuid(),
   order: z.number().int().min(0).max(99).default(0),
   slug: slugField,
});

export type CreateInsuranceDto = z.infer<typeof createInsuranceSchema>;
