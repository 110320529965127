import { z } from 'zod';
import { zipCodeField } from '../common';

const nonEmptyStringZodPipe = (errorMessage: string) =>
   z
      .string()
      .transform((t) => t?.trim())
      .pipe(z.string().min(1, errorMessage));

export const billingSchema = z.object({
   firstName: nonEmptyStringZodPipe('First name is required'),
   lastName: nonEmptyStringZodPipe('Last name is required'),
   address_1: nonEmptyStringZodPipe('Address is required'),
   address_2: z.string().optional(),
   state: z.string().min(1, 'State is required.'),
   email: z
      .string()
      .email()
      .transform((t) => t?.trim())
      .pipe(z.string().min(1, 'Email is required')),
   phone: nonEmptyStringZodPipe('Phone number is required'),
   city: nonEmptyStringZodPipe('City is required'),
   zipCode: zipCodeField
      .transform((t) => t?.trim())
      .pipe(z.string().min(1, 'Zip code is required')),
});

export type BillingSchema = z.infer<typeof billingSchema>;
