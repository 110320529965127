export enum CdtAdsOriginEnum {
   InsidePlatform = 'inside',
   OutsidePlatform = 'outside',
}

type AdsCounterKey = `cdtf-ads-${number}`;

export interface CdtAdsCounter {
   [Key: AdsCounterKey]: CdtAdsOriginEnum;
   // Adding string signature to satisfy updateOrCreatePatientCdt<CdtAdsCounter>
   [Key: string]: unknown;
}
