export const SCHEDULING_EXTRA_TIME_AFTER_APPOINTMENT_MINUTES = 0;

// interval to be used for occupied slots
export const SCHEDULING_INTERVAL_MINUTES = 15;

// it means 10.00, 10.30 will be offered, but not 10.15, 10.20 etc
export const SCHEDULING_APPOINTMENT_ALIGNMENT_MINUTES = 30;

export const SCHEDULING_ALLOWED_RESCHEDULING_BEFORE_APPOINTMENT_HOURS = 24;

export const SCHEDULING_WORKING_HOURS_END = '17:00';

export const DEFAULT_PATIENT_TIMEZONE = 'US/Eastern';

// Initial value was 7 days. It didn't work well when there were holidays like Thanksgiving which is immedately followed by weekend
// meaning 4 days already taken. We are aiming for number of days where we are almost guaranteed to have a free slot for
// at least one clinician.
// 2022/12/12: Increased to 21 (from 14) because of the holiday season
export const SCHEDULING_DAYS_RANGE_FOR_AVAILABLE_CLINICIANS = 21;

export const EncounterCountSource = {
   Bank: 'bank',
   ErrorInsuranceRequired: 'error-insurance-required',
   Insurance: 'insurance',
} as const;

export type EncounterCountSourceType =
   (typeof EncounterCountSource)[keyof typeof EncounterCountSource];
