import _ from 'lodash';
import { type SchedulingError } from './SchedulingErrorBase.error';

export * from './EncounterMissing.error';
export * from './MaxCountExceeded.error';
export * from './UnsupportedReference.error';
export * from './WorkingHours.error';
export * from './ConflictingRules.error';
export * from './MaxCountExceeded.error';
export * from './SchedulingErrorBase.error';
export * from './ReschedulingNotAllowed.error';
export * from './TimeSlotOccupied.error';
export * from './AppointmentNotAvailableInProgram.error';
export * from './NoPatientProgramInProgress.error';
export * from './NoSchedulingRulesForProgram.error';

export function isSchedulingError(error: unknown): error is SchedulingError {
   return _.isObject(error) && (error as SchedulingError).__schedulingError;
}
