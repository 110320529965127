import { z } from 'zod';
import {
   type WelkinWebhookEventEntity,
   type WelkinWebhookEventSubtype,
} from './welkin-webhook.dto';

// Yes, there is z.nativeEnum, but we want to be as loose as possible with this validation, while still
// providing the WelkinWebhookEventEntity type which could be incomplete and could complicate webhook validation.
// We don't want to reject Welkin requests that we don't understand, so we just check if it's a string.
const welkinEventEntitySchema = z.custom<WelkinWebhookEventEntity>((val) => {
   return typeof val === 'string';
});

const welkinEventSubtypeSchema = z.custom<WelkinWebhookEventSubtype>((val) => {
   return typeof val === 'string';
});

export const welkinWebhookRequestDtoSchema = z.object({
   sourceId: z.string(), // not necessarily uuid, for programs it is program name
   eventEntity: welkinEventEntitySchema,
   eventSubtype: welkinEventSubtypeSchema,
   tenantName: z.string(),
   instanceName: z.string(),

   // seems like this is username or patient name
   sourceName: z.string().optional(),
   patientId: z.string().uuid().optional(),

   // depends on event + event subtype
   // For patient related events, here is patient's url and for user (clinician) related events clinician's url
   url: z.string().optional(),
});

export type WelkinWebhookRequestDto = z.infer<
   typeof welkinWebhookRequestDtoSchema
>;
