import _ from 'lodash';
import { type CreateAppointmentDto } from '../create-appointment.dto';

const BULK_APPOINTMENTS_CREATE_ERROR_NAME = 'appointments.bulkCreate';

export interface BulkAppointmentError {
   error: Error;
   appointment: CreateAppointmentDto;
}

export class BulkAppointmentsCreateError extends Error {
   override name = BULK_APPOINTMENTS_CREATE_ERROR_NAME;
   public errors: (BulkAppointmentError | null)[];

   constructor(
      appointments: CreateAppointmentDto[],
      results: PromiseSettledResult<unknown>[],
   ) {
      super(`Bulk appointment create error`);

      this.errors = results.map((res, index) => {
         if (res.status === 'rejected') {
            return {
               // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
               error: res.reason,
               appointment: appointments[index],
            };
         }
         return null;
      });
   }
}

export function isBulkAppointmentsCreateError(
   data: unknown,
): data is BulkAppointmentsCreateError {
   return (
      _.isObject(data) &&
      (data as BulkAppointmentsCreateError).name ===
         BULK_APPOINTMENTS_CREATE_ERROR_NAME
   );
}
