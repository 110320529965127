import { z } from 'zod';
import { stateField } from '../common';

export const patientShippingAddressSchema = z.object({
   addressLine1: z.string(),
   addressLine2: z.string(),
   state: stateField,
   zip: z.string(),
   city: z.string(),
});
export type PatientShippingAddressDto = z.infer<
   typeof patientShippingAddressSchema
>;
