export const ELIGIBILITY_ERRORS = {
   NO_ELIGIBILITY_DATA: 'NO_ELIGIBILITY_DATA',
   NON_COMMERCIAL_INSURANCE_TYPE: 'NON_COMMERCIAL_INSURANCE_TYPE',

   // ClaimMD returned commercial insurance type, but plan name matches medicare
   MEDICARE_INSURANCE_PLAN: 'MEDICARE_INSURANCE_PLAN',
   UNKNOWN_ERROR: 'UNKNOWN_ERROR',
   NO_INSURANCE_TYPE: 'NO_INSURANCE_TYPE',
   NO_ACTIVE_COVERAGE: 'NO_ACTIVE_COVERAGE',
} as const;

// If we get a valid response, but classify the patient as ineligible,
// this is the error we will return to the client.
export type NonEligibleError = keyof typeof ELIGIBILITY_ERRORS;

export const getClientFriendlyEligibilityError = (error: NonEligibleError) => {
   switch (error) {
      case ELIGIBILITY_ERRORS.NO_ELIGIBILITY_DATA:
         return 'No eligibility data found in the response.';
      case ELIGIBILITY_ERRORS.NON_COMMERCIAL_INSURANCE_TYPE:
         return 'You have a non-commercial insurance type.';
      case ELIGIBILITY_ERRORS.NO_INSURANCE_TYPE:
         return 'No insurance type found in the response. We cannot determine eligibility.';
      case ELIGIBILITY_ERRORS.NO_ACTIVE_COVERAGE:
         return 'No active coverage found in the response.';
      case ELIGIBILITY_ERRORS.MEDICARE_INSURANCE_PLAN:
         return 'Medicare insurance plans are not supported.';
      case ELIGIBILITY_ERRORS.UNKNOWN_ERROR:
      default:
         return 'Unknown error';
   }
};
