import { z } from 'zod';
import { intExtendedField } from '../common';
import { FormsortFormTypes } from './formsort-form-types';

export const formsortListParamsDto = z.object({
   take: intExtendedField.optional(),
   skip: intExtendedField.optional(),
   variant_uuid: z.string().optional(),
   formsort_form_type: z.array(z.nativeEnum(FormsortFormTypes)).optional(),
});

export type FormsortListParamsDto = z.infer<typeof formsortListParamsDto>;
