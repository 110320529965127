import { z } from 'zod';
import { WelkinEncounterTemplateNames } from '../welkin';

export const isEncounterReschedulableParamsDtoSchema = z.object({
   encounterTemplate: z.nativeEnum(WelkinEncounterTemplateNames),
});

export type IsEncounterReschedulableParamsDto = z.infer<
   typeof isEncounterReschedulableParamsDtoSchema
>;
