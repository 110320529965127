import { WelkinEncounterTemplateNames } from '../welkin';

export const APPOINTMENT_BANK_PROGRAM_APPOINTMENTS = [
   WelkinEncounterTemplateNames.MedicalConsult,
   WelkinEncounterTemplateNames.MedicalFollowUp,
   WelkinEncounterTemplateNames.CapstoneAppointment,

   // General Psych Prog
   WelkinEncounterTemplateNames.MedicalConsultPsychiatry,
   // Therapy
   WelkinEncounterTemplateNames.TherapistIntake50Min,
   WelkinEncounterTemplateNames.MeetYourTherapistALaCarte,
   // EMDR
   WelkinEncounterTemplateNames.EmdrSession,
   WelkinEncounterTemplateNames.EmdrIntake,
   WelkinEncounterTemplateNames.EmdrIntakeInsurance,
];

export const APPOINTMENT_BANK_THERAPY_APPOINTMENTS = [
   WelkinEncounterTemplateNames.MeetYourTherapistALaCarte,
   WelkinEncounterTemplateNames.Preparation30MinALaCarte,
   WelkinEncounterTemplateNames.Preparation50MinALaCarte,
   WelkinEncounterTemplateNames.Integration30MinALaCarte,
   WelkinEncounterTemplateNames.Integration50MinALaCarte,
   WelkinEncounterTemplateNames.SupervisedDosingALaCarte,

   // TODO(AHC-205): included in program appts above and also here in therapy
   WelkinEncounterTemplateNames.EmdrSession,
   WelkinEncounterTemplateNames.EmdrIntake,
   WelkinEncounterTemplateNames.EmdrIntakeInsurance,

   WelkinEncounterTemplateNames.TalkTherapy30Min,
   WelkinEncounterTemplateNames.TalkTherapy50Min,
   WelkinEncounterTemplateNames.PsychedelicIntegration30Min,
   WelkinEncounterTemplateNames.PsychedelicIntegration50Min,
];

export const APPOINTMENT_BANK_MED_MANAGEMENT_APPOINTMENTS = [
   WelkinEncounterTemplateNames.MedicalCheckIn,
];

export const APPOINTMENT_BANK_ADDON_APPOINTMENTS = [
   ...APPOINTMENT_BANK_THERAPY_APPOINTMENTS,
   ...APPOINTMENT_BANK_MED_MANAGEMENT_APPOINTMENTS,
];

export const ALL_APPOINTMENT_BANK_APPOINTMENTS = [
   ...APPOINTMENT_BANK_PROGRAM_APPOINTMENTS,
   ...APPOINTMENT_BANK_THERAPY_APPOINTMENTS,
   ...APPOINTMENT_BANK_MED_MANAGEMENT_APPOINTMENTS,

   // not sure where these belong
   WelkinEncounterTemplateNames.MedicalCheckInPsychiatry,
];

export * from './addon-magento-categories-response.dto';
export * from './addon-purchases-response.dto';
